.admin__changes__main{
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: space-between ;
    align-items: center;
   
    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2,1fr);
        height: fit-content;
        &>.dark_red_title{
            font-size: xx-large;
            /* margin: auto !important; */
            text-align: center;
            padding: 0 10%;
            }
          
     }
     @media screen and (max-width: 600px) {
  
       }
}
.admin__changes__main .btns__container{
    display: grid;
   grid-template-columns: repeat(3,1fr);
width: 80%;
margin: auto;
// justify-content: space-between;
gap: 15px;
@media screen and (max-width: 1200px) {
   grid-template-columns: repeat(2,1fr);
}
@media screen and (max-width: 600px) {
    grid-template-columns: repeat(1,1fr);
  }
}
.admin__changes__main .btns__container .change__btn{
    border-radius: 15px;
    border: 0;
    background: #bd7575;
    font-size: 21px;
    font-weight: 700;
height: 74px;
padding: 10px 35px 10px 35px;
gap: 10px;
border-radius: 13px;
// opacity: 0px;
box-shadow: 0px 0px 8px 0px #000000A6;

}
.admin__changes__main .btns__container .change__btn:hover{
    -webkit-filter: brightness(80%);
    -webkit-transition: all 250ms ease;
    -moz-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    -ms-transition: all 250ms ease;
    transition: all 250ms ea250mse;
}
.wrapper{
    padding: 15px 50px;
    @media screen and (max-width: 600px) {
        padding: 0;
    }
}