.container-header {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    z-index: 10;
    padding: 0 25px;
    width: 100%;
    gap: 25px;

    @media screen and (min-width: 920px) {
        height: 128px;
    }

    @media screen and (min-width: 1150px) {
        width: 1200px;
    }
}

.main-header {
    display: flex;
    justify-content: center;
    background-color: var(--b-color-bg-main-1);

    position: sticky;
    z-index: 10;
    top: 0;
    transition: 0.3s ease;

    box-shadow: 0 5px 20px 0px var(--b-color-bg-main-3);
}

.burger_menu {
    width: 30px;
    height: 30px;

    @media screen and (min-width: 921px) {
        display: none;
    }
}

.header {
    z-index: 5;
    text-align: center;
    background-color: var(--b-color-bg-main-1);
    border-bottom: none;

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 25px;
    }

    &__menu_buttons {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;

        @media screen and (max-width: 920px) {
            display: none;
        }
    }

    &__action_buttons {
        display: flex;
        flex-direction: column;
        row-gap: 7px;

        @media screen and (max-width: 920px) {
            display: none;
        }
    }

    &.main-header {
        @media (max-width: 1199px) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1000;
        }
    }

    &__nav-link {
        width: 100%;
        color: var(--b-color-text-primary-1);

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: min-content;

            >span {
                white-space: nowrap;
            }

            >img {
                width: 100%;
            }
        }
    }

    &.show-menu {
        position: fixed;
        inset: 0px;
        align-items: flex-start;
        z-index: 9999;
    }

    &__top {
        width: 100%;
    }

    &__top__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: var(--secondary-color);
    }

    .navbar-toggler {
        outline: none;
        box-shadow: none;
    }

    &__right-bottom {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
    }

    &__logo {
        margin-top: 0px;
        height: auto;
        max-width: 200px;
        max-height: 50px;

        @media screen and (max-width: 1200px) {
            height: 42px;
        }

        @media screen and (max-width: 600px) {
            height: 36px;
        }

        @media screen and (max-width: 360px) {
            height: 28px;
        }
    }

    &__logo-mouth {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--b-color-text-primary-1);
        font-family: var(--b-font-family-primary-1);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 20px 0;
        background-color: var(--b-color-bg-main-1);
        width: min-content;

        @media screen and (max-width: 1200px) {
            font-size: 16px;
        }

        @media screen and (max-width: 920px) {
            // background-color: var(--b-color-bg-main-2);
            width: 100%;
        }

        @media screen and (max-width: 600px) {
            // background-color: var(--b-color-bg-main-2);
            width: 100%;
            font-size: 18px;

        }

        &>span {
            // white-space: nowrap;
            min-width: 140px;
            max-width: 160px;
            text-align: center;
        }

        &>img {
            width: 110%;

            @media screen and (max-width: 500px) {
                width: 40%;
            }
        }

        &:hover {
            background-color: var(--b-color-bg-main-1);
        }

        &.contact {
            flex-direction: row;
            justify-content: center;
            position: relative;
            background-color: var(--b-color-bg-main-1);

            &>img {
                width: 24px;
                position: absolute;
                right: 20px;
            }
        }


        &.select {
            position: relative;
            background-color: var(--b-color-bg-main-1);
            padding: 20px 0 35px 0;

            &>img {
                width: 125px;
                position: relative;
                right: 0px;
                top: 10px;
            }
        }
    }

    &__contact-box {
        background-color: var(--b-color-bg-main-1);
        width: 100%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    &__contact-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--b-color-bg-main-1);
        font-size: 16px;
        color: var(--b-color-text-primary-1);
        font-family: var(--b-font-family-primary-1);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-variant-numeric: lining-nums;
        width: 235px;
        gap: 7px;
        white-space: nowrap;

        &>img {
            width: 28px;
        }
    }

    &__report-box {
        background-color: var(--b-color-bg-main-1);
        width: 100%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        &__type {
            color: var(--b-color-text-primary-2);
            background-color: var(--b-color-main-2);
            font-family: var(--b-font-family-primary-1);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: center;
            padding: 10px;
            border-radius: 15px;
            box-shadow: 4px 3px 10px 0px var(--b-shadow-main);
            min-width: 250px;
        }
    }

    &__pet-box {
        background-color: var(--b-color-bg-main-1);
        width: 100%;
        padding: 20px 0;

        > :nth-child(n + 3) {
            margin: 10px 0 0 0;
        }

        &__title {
            color: var(--b-color-text-primary-1);
            font-family: var(--b-font-family-primary-1);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: center;
            padding: 0 0 15px 0;
        }
    }

    &__active-link {
        font-weight: 700;
    }

    &__claim-btn {
        @media (min-width: 992px) {
            margin-left: 1.5rem;
        }

        font-weight: 700;
        padding: 9px 17.64px 9px 16px;
        border-radius: 12px;
        color: var(--b-color-text-primary-2);
        leading-trim: both;
        text-edge: cap;
        font-family: var(--b-font-family-primary-1);
        font-size: 20px;
        font-style: normal;
        font-weight: 420;
        line-height: 24px;

        &:hover {
            color: var(--primary-color);
        }
    }

    &__logout {
        cursor: pointer;
        margin-left: 1.5rem;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: 10px;

        @media (max-width: 1199px) {
            margin: 0;
        }

        &.center {
            justify-content: center;
        }

        &__user {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: var(--b-color-bg-additional-2);
            color: var(--b-color-text-primary-2);
            font-family: var(--b-font-family-primary-1);
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            @media screen and (max-width: 600px) {
                width: 30px;
                height: 30px;
            }
        }
    }

    &__user {
        background-color: inherit;
        border: none;
    }

    &__logout-icon {
        margin-left: 16px;
    }

    .logout-btn {
        border: none;
        background-color: inherit;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.navbar-nav-column {
    display: grid;
    justify-items: flex-start;

    @media (max-width: 1199px) {
        justify-items: center;
    }
}

.navbar {
    flex-direction: column;
    padding: 0;

    &__flag {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: inherit;

        @media screen and (max-width: 600px) {
            width: 30px;
            height: 30px;
        }

        >img {
            width: 100%;
            height: 100%;
        }
    }
}

.phone-number {
    color: inherit;
    text-decoration: none;
    cursor: unset;
}

.phone-number:hover {
    color: inherit;
}