@use './buttons.scss';

strong,
b {
    font-weight: 700;
}

a {
    background-color: initial;
    text-decoration: none;
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

h1 {
    @media screen and (min-width: 1200px) {
        font-size: 3.125rem;
    }
}

body {
    background-color: var(--b-color-text-primary-2);
    color: #000;
    color: var(--b-color-text-body, #000);
    font-family: var(--font-primary), sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    margin: 0;
    text-align: left;
    overflow-y: auto;
}

// ================ FONTS ===================

.headline1 {
    font-family: var(--b-font-family-primary-1);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-variant-numeric: lining-nums;
}

.headline2 {
    font-family: var(--b-font-family-primary-1);
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-variant-numeric: lining-nums;
}

.headline1_red_title {
    color: var(--b-color-main-1);
    @extend .headline1;

    &.width100 {
        width: 100%;
    }

    @media screen and (max-width: 1200px) {
        width: 80%;
        font-size: 32px;
    }

    @media screen and (max-width: 600px) {
        text-align: center;
        font-size: 24px;
    }
}

.dark_red_title {
    color: var(--b-color-main-1);
    font-family: var(--b-font-family-primary-1);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-variant-numeric: lining-nums;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.dark_red_small {
    color: var(--b-color-main-1);
    text-align: center;
    font-family: var(--b-font-family-primary-1);
    font-size: 18px;
    font-style: normal;
    font-weight: 460;
    line-height: normal;
    font-variant-numeric: lining-nums;
}

.black_fat_rooney {
    color: var(--b-color-text-primary-1);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--b-font-family-primary-1);
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-variant-numeric: lining-nums;

    @media screen and (min-width: 600px) {
        font-size: 16px;
    }

    @media screen and (min-width: 900px) {
        font-size: 21px;
    }
}

.black_fat_verveine {
    color: var(--b-color-text-primary-1);
    font-family: "Verveine";
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-variant-numeric: lining-nums;
}

.white_big_verveine {
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: Verveine;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-variant-numeric: lining-nums;
}

.hl2_white_big {
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: var(--b-font-family-primary-1);
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-variant-numeric: lining-nums;

    @media screen and (max-width: 1200px) {
        font-size: 28px;
    }

    @media screen and (max-width: 900px) {
        font-size: 20px;
    }

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
}

.hl2_black_big {
    color: var(--b-color-text-primary-1);
    font-family: var(--b-font-family-primary-1);
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-variant-numeric: lining-nums;

    @media screen and (max-width: 1200px) {
        font-size: 28px;
    }

    @media screen and (max-width: 600px) {
        font-size: 20px;
    }
}

.white_fat_rooney {
    color: var(--b-color-text-primary-2);
    font-family: var(--b-font-family-primary-1);
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-variant-numeric: lining-nums;

    @media screen and (max-width: 1200px) and (orientation: landscape) {
        font-size: 18px;
    }

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
}

.white_slim_rooney {
    color: var(--b-color-text-primary-2);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: var(--b-font-family-primary-1);
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-variant-numeric: lining-nums;

    &.small {
        font-size: 16px;
    }

    @media screen and (max-width: 1200px) and (orientation: landscape) {
        font-size: 18px;
    }

    @media screen and (max-width: 600px) {
        font-size: 16px;
    }
}

.paws_layer {
    z-index: 5;
}

.support-widget {
    position: fixed;
    bottom: 10%;
    right: 3%;
    z-index: 10;

    &>img {
        transition: 0.3s;
        animation: 2s ease 0s infinite normal none running pulse;

        @keyframes pulse {
            0% {
                transform: scale(0.95);
            }

            70% {
                transform: scale(1);
            }

            100% {
                transform: scale(0.95);
            }
        }
    }
}