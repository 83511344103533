.pet_container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    margin: 40px 0;

    @media screen and (max-width: 1200px) {
        margin: 0 0 40px 0;
    }

    &__row {
        display: flex;
        justify-content: center;
        column-gap: 120px;
        background-image: var(--bgi-wave-1);
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: 100% 250px;
        margin: 40px 0;

        @media screen and (max-width: 1200px) {
            background-image: var(--bgi-wave-1-mobile);
            background-position-y: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin: 0 0 3.5vh 0;
            gap: 0;
            justify-content: space-evenly;
        }
    }

    &__row-modal {
        display: flex;
        justify-content: center;
        column-gap: 15px;

        @media screen and (min-width: 760px) {
            column-gap: 30px;
        }
    }

    &__row-modal:not(:first-child) {
        margin: 5px 0 0 0;
    }

    &__row-menu {
        display: flex;
        justify-content: center;
        column-gap: 15px;
    }

    &__item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.empty {
            display: none;
        }

        &.not-selected {
            opacity: 0.5;
        }
    }

    &__wrapper {
        width: 192px;
        height: 246px;
        flex-shrink: 0;
        border-radius: 32px;
        background: rgba(198, 91, 88, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.5s ease, width 0.5s ease, height 0.5s;

        &>div {
            width: 158px;
            height: 214px;
            flex-shrink: 0;
            border-radius: 28px;
            border: 11px solid var(--b-color-main-1);
            background: var(--b-color-bg-additional-1);
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            transition: opacity 0.5s ease, width 0.5s ease, height 0.5s;
            overflow-x: hidden;
        }

        @media screen and (max-width: 680px) {
            width: 130px;
            height: 166px;
            border-radius: 24px;

            &>div {
                width: 108px;
                height: 144px;
                border: 6px solid var(--b-color-main-1);
                border-radius: 20px;
            }

            &.small {
                width: 102px;
                height: 130px;
                border-radius: 20px;

                &>div {
                    width: 84px;
                    height: 112px;
                    border: 5px solid var(--b-color-main-1);
                    border-radius: 16px;
                }
            }
        }

        @media screen and (max-width: 380px) {
            width: 116px;
            height: 146px;
            border-radius: 24px;

            &>div {
                width: 92px;
                height: 122px;
                border: 6px solid var(--b-color-main-1);
                border-radius: 20px;
            }

            &.small {
                width: 98px;
                height: 126px;
                border-radius: 20px;

                &>div {
                    width: 82px;
                    height: 109px;
                    border: 4px solid var(--b-color-main-1);
                    border-radius: 16px;
                }
            }
        }
    }

    &__wrapper-modal {
        width: 150px;
        height: 192px;
        flex-shrink: 0;
        border-radius: 25px;
        background: rgba(198, 91, 88, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.5s ease, width 0.5s ease, height 0.5s;

        &>div {
            width: 122px;
            height: 166px;
            flex-shrink: 0;
            border-radius: 20px;
            border: 8px solid var(--b-color-main-1);
            background: var(--b-color-bg-additional-1);
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            transition: opacity 0.5s ease, width 0.5s ease, height 0.5s;
            overflow-x: hidden;
        }

        @media screen and (max-width: 760px) {
            width: 98px;
            height: 126px;
            border-radius: 20px;

            &>div {
                width: 82px;
                height: 109px;
                border: 4px solid var(--b-color-main-1);
                border-radius: 16px;

            }
        }

        &.small {
            @media screen and (max-width: 760px) {

                width: 78px;
                height: 100px;
                border-radius: 18px;

                &>div {
                    width: 65px;
                    height: 86px;
                    border: 4px solid var(--b-color-main-1);
                    border-radius: 16px;
                }
            }
        }
    }

    &__wrapper-inactive {
        width: 154px;
        height: 198px;
        flex-shrink: 0;
        border-radius: 36px;
        background: rgba(198, 91, 88, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.5;
        transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease;

        >div {
            width: 115px;
            height: 161px;
            flex-shrink: 0;
            border-radius: 28px;
            border: 11px solid var(--b-color-main-1);
            background: var(--b-color-bg-additional-1);
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            transition: opacity 0.5s ease, width 0.5s ease, height 0.5s;
            overflow-x: hidden;
        }

        >div img {
            overflow: hidden;
        }

        @media screen and (max-width: 680px) {
            width: 110px;
            height: 140px;
            border-radius: 24px;

            &>div {
                width: 86px;
                height: 115px;
                border: 6px solid var(--b-color-main-1);
                border-radius: 20px;
            }

            &>div img {
                height: 100%;
            }

            &.small {
                width: 102px;
                height: 130px;
                border-radius: 20px;

                &>div {
                    width: 84px;
                    height: 112px;
                    border: 5px solid var(--b-color-main-1);
                    border-radius: 16px;
                }

                &>div img {
                    height: 100%;
                }
            }
        }

        @media screen and (max-width: 380px) {
            width: 104px;
            height: 130px;
            border-radius: 24px;

            &>div {
                width: 80px;
                height: 106px;
                border: 6px solid var(--b-color-main-1);
                border-radius: 20px;
            }

            &>div img {
                height: 100%;
            }

            &.small {
                width: 102px;
                height: 130px;
                border-radius: 20px;

                &>div {
                    width: 84px;
                    height: 112px;
                    border: 5px solid var(--b-color-main-1);
                    border-radius: 16px;
                }

                &>div img {
                    height: 100%;
                }
            }
        }

        @media screen and (max-width: 600px) {
            width: 110px;
            height: 140px;
            border-radius: 24px;

            &>div {
                width: 86px;
                height: 115px;
                border: 6px solid var(--b-color-main-1);
                border-radius: 20px;
            }

            &>div img {
                // width: 100px;
                height: 100%;
            }

            &.small {
                width: 102px;
                height: 130px;
                border-radius: 20px;

                &>div {
                    width: 84px;
                    height: 112px;
                    border: 5px solid var(--b-color-main-1);
                    border-radius: 16px;
                }

                &>div img {
                    // width: 100%;
                    height: 100%;
                }
            }
        }
    }

    &__single {
        width: 242px;
        height: 312px;
        flex-shrink: 0;
        border-radius: 36px;
        background: rgba(198, 91, 88, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 900px) {
            width: 212px;
            height: 268px;
            border-radius: 26px;
        }

        @media screen and (max-width: 600px) {
            width: 138px;
            height: 174px;
            border-radius: 20px;
        }

        &>div {
            width: 200px;
            height: 270px;
            flex-shrink: 0;
            border-radius: 28px;
            border: 11px solid var(--b-color-main-1);
            background: var(--b-color-bg-additional-1);
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: content-box;
            position: relative;

            @media screen and (max-width: 900px) {
                width: 170px;
                height: 226px;
                border: 11px solid var(--b-color-main-1);
                border-radius: 20px;
            }

            @media screen and (max-width: 600px) {
                width: 113px;
                height: 150px;
                border: 6px solid var(--b-color-main-1);
                border-radius: 16px;
            }
        }

        &__parent {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            height: 100%;
            overflow-x: hidden;
            border-radius: 16px;

            @media screen and (max-width: 900px) {
                border-radius: 9px;
            }

            @media screen and (max-width: 600px) {
                border-radius: 10px;
            }
        }

        &__upload {
            background: rgba(198, 91, 88, 0.5);
            color: var(--b-color-bg-main-2);
            text-align: center;
            font-family: var(--b-font-family-primary-1);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: 100%;
            height: 35px;
            position: absolute;
            bottom: 0px;
            border-radius: 0 0 15px 15px;
            padding: 5px;
            cursor: pointer;

            @media screen and (max-width: 900px) {
                border-radius: 0 0 9px 9px;
            }

            @media screen and (max-width: 600px) {
                font-size: 10px;
                height: 20px;
            }

            &.mobile {
                height: 25px;
                font-size: 10px;
                border-radius: 0 0 10px 10px;
            }
        }
    }

    &__name {
        color: var(--b-color-main-1);
        font-family: var(--b-font-family-primary-1);
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        text-align: center;
        font-variant-numeric: lining-nums;
        min-height: 58px;

        &>div {
            color: var(--b-color-text-primary-1);
            font-size: 20px;
        }

        @media screen and (max-width: 680px) {
            font-size: 16px;
            max-width: 102px;
            min-height: 36px;

            &>div {
                font-size: 14px;
            }
        }

        @media screen and (max-width: 380px) {
            font-size: 12px;
            max-width: 98px;
            min-height: 26px;

            &>div {
                font-size: 12px;
            }
        }
    }
}

.cat_dog {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    &__circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 140px;
        flex-shrink: 0;
        border-radius: 50%;
        background: #a8403e;
        box-shadow: 0px 4px 4px 0px var(--b-shadow-main);
        margin-right: -50px;
        z-index: 5;
    }

    &__title {
        border-radius: 140px;
        background: var(--b-color-main-1);
        color: var(--b-color-text-primary-2);
        leading-trim: both;
        text-edge: cap;
        font-family: Verveine;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: inline-flex;
        padding: 0 80px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}

.arrow_back {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin: 0 3vw;
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: var(--b-font-family-primary-1);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    @media screen and (max-width: 900px) {
        margin: 0;
        font-size: 16px;
        column-gap: 0px;
    }

    span {
        color: var(--b-color-text-primary-2);
        leading-trim: both;
        text-edge: cap;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media screen and (max-width: 900px) {
            font-size: 18px;
        }
    }
}

.pet_main {
    width: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: var(--bgi-wave-2);
    background-size: 100% 90%;
    background-repeat: no-repeat;
    background-position-y: center;
    column-gap: 1vw;

    @media screen and (max-width: 900px) {
        justify-content: space-evenly;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin: 0 15vw;
        width: 60vw;

        @media screen and (max-width: 900px) {
            margin: 0;
        }

        @media screen and (max-width: 600px) and (orientation: landscape) {
            margin: 1.5vh 0 0 0;
            width: 50vw;
        }

        @media screen and (max-width: 600px) {
            // width: auto;
        }
    }

    &__table {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin: 2.5vh 0 2.5vh;

        @media screen and (min-width: 600px) {
            margin: 4vh 0 3vh;
            gap: 12px;
        }

        &__item {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 5px;

            @media screen and (min-width: 600px) {
                gap: 10px;
            }

            &__left {
                width: 150px;
                display: flex;
                flex-direction: column;
                align-items: end;
                row-gap: 6px;
                white-space: nowrap;
            }

            &__right {
                display: flex;
                flex-direction: column;
                align-items: start;
                row-gap: 6px;
                white-space: nowrap;

                &>div {
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media screen and (max-width: 600px) {
                        max-width: 150px;
                    }
                }

                form {
                    display: flex;
                    flex-direction: row;
                    gap: 6px;

                    .input-chip {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        @media screen and (min-width: 600px) {
                            flex-direction: row;
                        }
                    }

                    .input-and-message {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        margin: 7px 7px 0px 7px;

                        .input-and-exclamation {
                            position: relative;

                            input {
                                min-width: 200px;
                                height: 30px;
                                border-radius: 13px;
                                border: none;
                                padding: 5px 7px;
                                font-family: var(--b-font-family-primary-1);
                                font-size: var(--b-font-size-sm);
                                font-weight: 500;
                                font-variant-numeric: lining-nums;
                                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.65);

                                @media screen and (min-width: 600px) {
                                    min-width: 320px;
                                    height: 40px;
                                    font-size: var(--b-font-size-xl);
                                    padding: 5px 15px;
                                }
                            }

                            input::placeholder {
                                color: var(--b-color-additional-1);
                                font-family: var(--b-font-family-primary-1);
                                font-size: var(--b-font-size-sm);
                                font-weight: 600;
                                font-variant-numeric: lining-nums;

                                @media screen and (min-width:600px) {
                                    font-size: var(--b-font-size-xl);
                                }
                            }

                            img {
                                position: absolute;
                                top: 5px;
                                right: 6px;
                                width: 20px;
                                height: 20px;

                                @media screen and (min-width: 600px) {
                                    top: 8px;
                                    right: 8px;
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }

                        .input-message {
                            max-width: 200px;
                            font-family: var(--b-font-family-primary-1);
                            font-size: 12px;
                            font-weight: 600;
                            font-variant-numeric: lining-nums;
                            white-space: break-spaces;

                            @media screen and (min-width: 600px) {
                                max-width: unset;
                                font-size: var(--b-font-size-m);
                                padding: 0 0 0 10px;
                            }
                        }
                    }


                    button {
                        height: 40px;
                        width: 70px;
                        border-radius: 13px;
                        border: none;
                        padding: 5px 15px;
                        margin: 7px 10px 7px 0px;
                        background-color: var(--b-color-bg-main-2);
                        color: var(--b-color-main-2);
                        font-weight: 600;
                        box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.25);
                    }

                    button:hover {
                        background-color: var(--b-color-bg-main-3);
                    }

                    button:disabled {
                        background-color: var(--b-color-additional-1);
                        color: var(--b-color-text-primary-2);
                    }
                }


            }
        }


    }

    &.mobile {
        background-image: var(--bgi-wave-2-mobile);
        background-position-y: bottom;
        flex-direction: column;
        gap: 1vh;
        margin: 20px 0 60px 0;
        padding: 0px 0 40px 0;
        background-size: 100% 80%;

        @media screen and (min-width: 600px) and (max-width: 1200px) {
            background-size: 100% 80%;
        }
    }
}

.gradient_bar {
    width: 550px;
    height: 3px;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0) 100%);

    @media screen and (max-width: 1200px) {
        width: 100%;
    }
}

.bubble_change_data {
    cursor: pointer;

    @media screen and (max-width: 900px) {
        width: 61px;
    }

    @media screen and (max-width: 600px) {
        width: 45px;
    }
}

.pet_product_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0 40px 0;

    @media screen and (max-width: 600px) {
        margin: 40px 0;
    }
}

.documents_download {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;
    margin: 40px 0;

    &__title {
        color: var(--b-color-text-primary-1);
        font-family: var(--b-font-family-primary-1);
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 3vh 0;

        @media screen and (max-width: 1200px) {
            font-size: 28px;
            margin: 0;
        }

        @media screen and (max-width: 600px) {
            font-size: 20px;
            margin: 0;
        }
    }

    &__link {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &__circle {
            z-index: 5;
            width: 74px;
            height: 74px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--b-color-main-2);
            border: 4px solid var(--b-color-text-primary-2);
            margin-right: -35px;
            box-sizing: content-box;

            img {
                width: 44px;
                height: 44px;
            }

            &.additionall-item {
                width: 37px;
                height: 37px;
                border: 2px solid var(--b-color-bg-main-1);

                img {
                    width: 22px;
                    height: 22px;
                }
            }

            @media screen and (max-width: 1200px) {
                width: 50px;
                height: 50px;
                border: 3px solid var(--b-color-bg-main-1);

                img {
                    width: 28px;
                    height: 28px;
                }
            }

            @media screen and (max-width: 600px) {
                width: 37px;
                height: 37px;
                border: 2px solid var(--b-color-bg-main-1);

                img {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        &__bar {
            display: flex;
            padding: 13px 30px 13px 50px;
            justify-content: center;
            align-items: center;
            border-radius: 140px;
            background: var(--b-color-main-2);
            color: var(--b-color-text-primary-2);
            leading-trim: both;
            text-edge: cap;
            font-family: var(--b-font-family-primary-1);
            font-size: 24px;
            font-style: normal;
            font-weight: 420;
            line-height: normal;

            &.additionall-item {
                font-size: 12px;
                padding: 7px 13px 7px 40px;
            }

            @media screen and (max-width: 1200px) {
                font-size: 18px;
                padding: 9px 20px 9px 45px;
            }

            @media screen and (max-width: 600px) {
                font-size: 12px;
                padding: 7px 13px 7px 40px;
            }
        }
    }

    &__link:hover div {
        background-color: var(--b-color-main-1);
    }
}

.tariff_cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;

    @media screen and (max-width: 1200px) {
        width: 88%;
        margin: 0;
    }

    @media screen and (max-width: 600px) {
        width: 94%;
        margin: 0;
    }

    &__title {
        color: var(--b-color-text-primary-1);
        font-family: var(--b-font-family-primary-1);
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0 0 20px 0;

        @media screen and (max-width: 1200px) {
            font-size: 28px;
        }

        @media screen and (max-width: 600px) {
            font-size: 20px;
            margin: 0;
        }
    }

    &__table {
        width: 620px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        position: relative;
        position: relative;
        margin-top: 80px;

        >img {
            position: absolute;
            top: -79px;
            left: 50%;
            transform: translateX(-50%);
        }

        @media screen and (max-width: 1200px) {
            width: 100%;
            row-gap: 5px;
            margin-top: 70px;

            >img {
                width: 120px;
                top: -71px;
            }
        }

        @media screen and (max-width: 600px) {
            >img {
                width: 100px;
                top: -67px;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        column-gap: 10px;

        @media screen and (max-width: 1200px) {
            height: auto;
            min-height: 35px;
            column-gap: 5px;
        }
    }

    &__left {
        flex: 1;
        display: flex;
        // width: 380px;
        // height: 70px;
        padding: 20px 30px;
        justify-content: flex-start;
        align-items: center;
        background: var(--b-color-main-1);
        color: var(--b-color-text-primary-2);
        border-radius: 14px 0 0 14px;
        leading-trim: both;
        text-edge: cap;
        font-family: var(--b-font-family-primary-1);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        @media screen and (max-width: 1200px) {
            width: 200px;
            height: auto;
            padding: 10px 30px;
            border-radius: 7px 0 0 7px;
            font-size: 18px;
            padding: 10px 5px;
            text-align: center;
            justify-content: center;
        }

        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }

    &__right_container {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__right {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
        min-height: 40px;
        background: var(--b-color-bg-additional-4);
        color: var(--b-color-text-primary-1);
        border-radius: 0 14px 14px 0;
        leading-trim: both;
        text-edge: cap;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: var(--b-font-family-primary-1);
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        position: relative;

        span {
            font-size: 16px;
        }

        @media screen and (max-width: 1200px) {
            height: auto;
            padding: 10px 30px;
            border-radius: 0 7px 7px 0;
            font-size: 18px;
            padding: 10px 5px;
            text-align: center;
            justify-content: center;

            span {
                font-size: 18px;
            }

            img {
                width: 20px;
            }
        }

        @media screen and (max-width: 600px) {
            font-size: 14px;

            span {
                font-size: 14px;
            }
        }

        &__details {
            width: 100%;

            &__title {
                font-size: 16px;

                @media screen and (max-width: 1200px) {
                    font-size: 14px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 12px;
                }
            }

            &__content {
                font-size: 21px;
                color: var(--b-color-main-1);

                @media screen and (max-width: 1200px) {
                    font-size: 18px;
                }

                @media screen and (max-width: 600px) {
                    font-size: 14px;
                }
            }
        }
    }

    &__right_few {
        @extend .tariff_cover__right;
        padding: 10px 30px;
    }

    &__right_few:not(:last-child) {
        margin: 0 0 10px 0;

        @media screen and (max-width: 1200px) {
            padding: 10px;
            margin: 0 0 5px 0;
        }
    }
}

.tariff_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;

    @media screen and (max-width: 1200px) {
        width: 88%;
    }

    @media screen and (max-width: 600px) {
        width: 94%;
    }

    &__title {
        color: var(--b-color-text-primary-1);
        font-family: var(--b-font-family-primary-1);
        font-size: 34px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 40px 0;

        @media screen and (max-width: 1200px) {
            font-size: 28px;
            margin: 20px 0;
        }

        @media screen and (max-width: 600px) {
            font-size: 20px;
            margin: 20px 0;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        @media screen and (max-width: 1200px) {
            width: 100%;
            align-items: center;
            row-gap: 5px;
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        column-gap: 10px;

        @media screen and (max-width: 1200px) {
            width: 100%;
            justify-content: center;
            column-gap: 5px;
        }

        &__left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 70px;
            border-radius: 14px 0 0 14px;
            background: var(--b-color-bg-additional-4);

            img {
                flex-shrink: 0;
            }

            @media screen and (max-width: 1200px) {
                width: 38px;
                height: 40px;
                border-radius: 7px 0 0 7px;

                img {
                    width: 23px;
                    height: 18px;
                }

                img.square24 {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &__right {
            position: relative;
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            justify-content: flex-start;
            align-items: center;

            @media screen and (max-width: 1200px) {
                width: 100%;
                row-gap: 5px;
            }

            &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 510px;
                height: 70px;
                border-radius: 0 14px 14px 0;
                background: var(--b-color-main-1);
                color: var(--b-color-text-primary-2);
                leading-trim: both;
                text-edge: cap;
                font-family: var(--b-font-family-primary-1);
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 0 25px;
                cursor: pointer;
                font-variant-numeric: lining-nums;

                @media screen and (max-width: 1200px) {
                    justify-content: space-between;
                    width: 100%;
                    height: 40px;
                    font-size: 18px;
                    border-radius: 0 7px 7px 0;

                    >img {
                        width: 21px;
                        height: 12px;
                    }
                }

                @media screen and (max-width: 600px) {
                    font-size: 14px;
                }
            }

            &__option {
                display: flex;
                flex-direction: column;
            }

            &__info {
                width: 510px;
                background: var(--b-color-bg-additional-4);
                border-radius: 14px;
                display: flex;
                padding: 35px 25px;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 20px;

                @media screen and (max-width: 1200px) {
                    width: 100%;
                    border-radius: 7px;
                }

                &__line {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    color: var(--b-color-text-primary-1);
                    leading-trim: both;
                    text-edge: cap;
                    font-variant-numeric: lining-nums proportional-nums;
                    font-family: var(--b-font-family-primary-1);
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;

                    >img {
                        margin: 5px 10px 0 0;
                    }

                    @media screen and (max-width: 1200px) {
                        font-size: 18px;

                        >img {
                            width: 15px;
                            height: 11px;
                        }

                        >img.square15 {
                            width: 15px;
                            height: 15px;
                        }
                    }

                    @media screen and (max-width: 600px) {
                        font-size: 14px;
                    }
                }

                &__ul {
                    padding: 0 0 0 50px;
                    color: var(--b-color-text-primary-1);
                    leading-trim: both;
                    text-edge: cap;
                    font-variant-numeric: lining-nums proportional-nums;
                    font-family: var(--b-font-family-primary-1);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 380;
                    line-height: normal;

                    @media screen and (max-width: 1200px) {
                        padding: 0 0 0 20px;
                    }

                    ul {
                        list-style-type: none;
                        padding-left: 10px;
                        margin: 0;
                    }

                    li {
                        padding-left: 15px;
                        background-image: var(--bgi-li-marker);
                        background-position: 0px 7px;
                        background-size: 8px;
                        background-repeat: no-repeat;

                        @media screen and (max-width: 1200px) {
                            font-size: 15px;
                        }

                        @media screen and (max-width: 600px) {
                            font-size: 13px;
                            background-position: 5px 6px;
                            background-size: 5px;
                        }
                    }
                }
            }

            &__tooltip {
                margin: 0 0 0 5px;
            }
        }
    }
}