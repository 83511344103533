.back_icon_red {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: var(--b-color-main-2);

    &.mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 27px;
        height: 27px;

        &>img {
            height: 17px;
        }
    }
}

.button_red_base {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--b-color-main-2);
    box-shadow: 4px 3px 10px 0px var(--b-shadow-light);
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: var(--b-font-family-primary-1);
    font-size: 21px;
    font-style: normal;
    font-variant-numeric: lining-nums;
    font-weight: 600;
    line-height: normal;
    text-align: center;

    @media screen and (max-width: 600px) {
        font-size: 14px;
    }

    &.disabled {
        background-color: var(--b-color-additional-1);
        cursor: not-allowed;
    }

    &.disabled:hover {
        background-color: var(--b-color-additional-1);
        cursor: not-allowed;
    }
}

.button_red_base:hover {
    background-color: var(--b-color-main-1) !important;
}

.button_account {
    @extend .button_red_base;
    width: 350px;
    height: 56px;
    padding: 13px 25px;
    border-radius: 14px;
    border: 0;
}

.button_account[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.button_account_mobile {
    @extend .button_red_base;
    width: 220px;
    height: 40px;
    padding: 15px 25px;
    border-radius: 11px;
    border: 0;
    font-size: 14px;
}

.button_account_mobile[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.button_red_main {
    @extend .button_red_base;
    padding: 13px 25px;
    border-radius: 13px;
}

.button_red_common {
    @extend .button_red_base;
    padding: 16px 30px;
    border-radius: 12px;
    line-height: 24px;
    height: 56px;

    @media screen and (max-width: 600px) {
        height: 40px !important;
        padding: 13px 25px;
    }

    &.dark {
        background-color: var(--b-color-main-1);
    }

    &.disabled {
        background-color: var(--b-color-additional-1);
        cursor: not-allowed;
    }

    &.white-change {
        padding: 10px 30px;
        height: 56px;
        background-color: var(--b-color-bg-main-2);
        color: var(--b-color-main-1);
    }

    &.white-change:hover {
        background-color: var(--b-color-bg-main-3) !important;
    }

    &.wide-change {
        padding: 10px 30px;
        height: 56px;
        width: 100%;
    }

    &.menu-docs {
        justify-content: space-between;
        padding: 10px 30px;
        height: 60px;
        width: 100%;
    }

    &.docs-item {
        background-color: var(--b-color-additional-1);
        padding: 10px 30px;
        min-height: 60px;
        width: 100%;
        justify-content: flex-start;
        gap: 20px;
    }
}

.button_red_common:hover {
    background-color: var(--b-color-main-1) !important;
}

.button_red_common:disabled {
    background-color: var(--b-color-additional-1);
    cursor: not-allowed;
}

.button_red_mobile {
    display: flex;
    justify-content: center;
    height: 40px;
    padding: 13px 25px;
    align-items: center;
    border-radius: 12px;
    background: var(--b-color-main-2);
    box-shadow: 4px 3px 10px 0px var(--b-shadow-light);
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: var(--b-font-family-primary-1);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-variant-numeric: lining-nums;

    & div {
        font-variant-numeric: lining-nums;
    }

    &.dark {
        background-color: var(--b-color-main-1);
    }

    &.disabled {
        background-color: var(--b-color-additional-1);
        cursor: not-allowed;
    }

    &.back {
        margin: 2vh auto 2vh 5vw;
        height: 40px;
        padding: 13px 15px;
        gap: 12px;

        &>img {
            width: 11px;
            height: 30px;
        }

        @media screen and (min-width: 600px) {
            height: 40px;
            font-size: 18px;
        }
    }

    &.menu-docs {
        justify-content: space-between;
        padding: 10px 30px;
        min-height: 40px;
        width: 100%;
        text-align: center;

        @media screen and (min-width: 600px) {
            width: 450px;
        }
    }

    &.docs-item {
        background-color: var(--b-color-additional-1);
        padding: 10px 30px;
        min-height: 40px;
        height: unset;
        width: 100%;
        justify-content: flex-start;
        gap: 15px;

        @media screen and (min-width: 600px) {
            width: 450px;
        }
    }
}

.button_red_small {
    cursor: pointer;
    min-height: 40px;
    width: 135px;
    display: flex;
    justify-content: center;
    padding: 0px 30px;
    align-items: center;
    gap: 10px;
    border-radius: 13px;
    background: var(--b-color-main-2);
    box-shadow: 0px 0px 10px 0px var(--b-shadow-main);
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: var(--b-font-family-primary-1);
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    border: 0;
    font-variant-numeric: lining-nums;

    &__admin {
        @extend .button_red_small;
        min-width: 100px;
        padding: 0 30px;
        width: 50%;
    }

    &.disabled,
    &:disabled {
        pointer-events: none;
        background: var(--b-color-additional-1);
    }

    &.mobile {
        width: 105px;
        font-size: 14px;
    }
}

.button_red_small:hover {
    background-color: var(--b-color-main-1);
}

.button_red_small_active {
    background-color: var(--b-color-main-1);
}

.button_red_smal_disabled {
    height: 40px;
    width: 135px;
    display: flex;
    justify-content: center;
    padding: 0px 30px;
    align-items: center;
    gap: 10px;
    border-radius: 13px;
    background: var(--b-color-additional-1);
    box-shadow: 0px 0px 10px 0px var(--b-shadow-main);
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: var(--b-font-family-primary-1);
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    &.mobile {
        width: 105px;
        font-size: 14px;
    }
}

.button_red {
    margin: 30px 0;
    border-radius: 14px;
    border: solid 3px var(--b-color-main-2);
    box-shadow: 4px 3px 10px 0px var(--b-shadow-light);
    background: var(--b-color-main-2);
    display: inline-flex;
    height: 56px;
    padding: 13px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: var(--b-font-family-primary-1);
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;

    @media screen and (max-width: 600px) {
        flex: 1 1 40%;
        font-size: 16px;
        padding: 10px 15px;
        height: unset;
    }
}

.button_red:hover {
    background: var(--b-color-bg-main-1);
    color: var(--b-color-main-2);
}

.button_red_disabled {
    @extend .button_red;
    cursor: not-allowed;
    opacity: 0.5;
}

.button_red_disabled:hover {
    background: var(--b-color-main-2);
    color: var(--b-color-text-primary-2);
    cursor: not-allowed;
}

.button_dark_red {
    border-radius: 14px;
    box-shadow: 4px 3px 10px 0px var(--b-shadow-light);
    background: var(--b-color-main-1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: var(--b-font-family-primary-1);
    font-size: 21px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
}

.button_dark_red:hover {
    background: var(--b-color-additional-3);
}

.button_red_report {
    @extend .button_dark_red;
    width: 200px;
    height: 35px;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    border: unset;

    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }
}

.button_red_contact {
    @extend .button_red_report;
    cursor: pointer;
    justify-content: space-around;

    &>div {
        width: 24px;
    }

    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }

    &.en {
        font-size: 15px;
    }
}

.buttons_menu_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--b-color-bg-main-1);
    padding: 16px 15px;
}

.button_dark-red_contact_mobile {
    width: 100%;
    max-width: 325px;
    height: 40px;
    padding: 13px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    border-radius: 11px;
    color: var(--b-color-text-primary-2);
    font-family: var(--b-font-family-primary-1);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: var(--b-color-main-1);
    box-shadow: 4px 3px 10px 0px var(--b-shadow-light);
}

.button_red_contact_mobile {
    @extend .button_dark-red_contact_mobile;
    background: var(--b-color-main-2);

}

.button_red_invoice {
    border: 0;
    cursor: pointer;
    display: flex;
    min-width: 180px;
    height: 56px;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    background: var(--b-color-main-2);
    box-shadow: 4px 3px 10px 0px var(--b-shadow-light);
    color: var(--b-color-text-primary-2);
    leading-trim: both;
    text-edge: cap;
    font-family: var(--b-font-family-primary-1);
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    text-align: center;

    &.mobile {
        min-width: 120px;
        width: 100%;
        height: 40px;
        padding: 0px 16px;
        font-size: 14px;
        line-height: normal;
    }

    &.disabled,
    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }
}

.button {
    border: 1px solid var(--b-color-support-info);
    border-radius: 8px;
    color: var(--b-color-support-info);
    cursor: pointer;
    display: flex;
    padding: 8px 16px;
}


.btn-reset {
    background: inherit;
    border: none;
    outline: none;
}

.btn {
    padding: 0.75rem 1.5rem;
    border-radius: var(--btn-border-radius, 0);
    font-weight: 700;
}

.switch-btn {
    margin-left: 1.5rem;

    @media (max-width: 1199px) {
        margin-left: 0;
    }
}

.button-lang {
    background-color: inherit;
    justify-content: center;
}

.edit-button {
    border: none;
    background-color: inherit;
}

.btn-go-top {
    border: none;
    border-radius: 32px;
    background: var(--b-color-main-1);
    width: 32px;
    height: 32px;
    position: absolute;
    margin-top: -50px;
    box-shadow: 2px 2px 4px 0px var(--b-shadow-light);
}

.btn-go-top__mobile {
    position: static;
    padding: 0;
    margin: 0 0 0 0;
    width: 27px;
    height: 27px;
    transition: 0.5s ease;
}