.login {
    background-color: var(--b-color-text-primary-2);

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin: 9vh 0 6vh 0;

        & span {
            text-align: center;
        }
    }

    &__main_back {
        width: 100%;
        min-height: 450px;
        position: relative;
        display: flex;
        justify-content: center;
        background-image: var(--bgi-login);
        background-size: 100% 100%;
        background-position-y: center;
        background-repeat: no-repeat;
        margin: 0 0 6vh 0;

        @media screen and (max-width: 900px) {
            background-size: cover;
        }

        &__dog {
            position: absolute;
            top: -2vh;
            left: 10vw;

            @media screen and (max-width: 1200px) {
                left: 5vw;
                height: 200px;
            }

            @media screen and (max-width: 900px) {
                left: 5vw;
                height: 150px;
            }

            @media screen and (max-width: 600px) {
                left: 5vw;
                height: 130px;
            }
        }

        &__cat {
            position: absolute;
            bottom: -2vh;
            right: 10vw;

            @media screen and (max-width: 1200px) {
                right: 2vw;
                height: 200px;
            }

            @media screen and (max-width: 900px) {
                right: 2vw;
                height: 150px;
            }

            @media screen and (max-width: 600px) {
                right: 2vw;
                height: 130px;
            }
        }
    }

    &__body {
        position: relative;
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 17vh 0;

        @media screen and (max-width: 1200px) {
            width: 50%;
        }

        @media screen and (max-width: 900px) {
            width: 60%;
        }

        @media screen and (max-width: 600px) {
            width: 70%;
        }

        &__title {
            display: flex;
            flex-direction: column;
            width: 80%;
            margin: 0 0 3vh 0;
        }

        &__description {
            display: flex;
            flex-direction: column;
            width: 86%;
            margin: 0 0 3vh 0;
        }

        &__input {
            width: 86%;
            height: 40px;
            display: flex;
            align-items: center;
            color: var(--b-color-text-primary-1);
            font-size: 20px;
            font-family: var(--b-font-family-primary-1);
            font-weight: 600;
            font-style: normal;
            line-height: 24px;
            leading-trim: both;
            text-edge: cap;
            font-variant-numeric: lining-nums proportional-nums;
            border-radius: 13px;
            border: 1px solid var(--b-color-bg-additional-5);
            padding: 0px 35px;
            background: var(--b-color-bg-main-2);
            box-shadow: 0px 0px 8px 0px var(--b-shadow-main);

            @media screen and (max-width: 900px) {
                width: 100%;
            }

            @media screen and (max-width: 600px) {
                width: 70vw;
            }

            @media screen and (max-width: 500px) {
                width: 86vw;
            }
        }

        &__input::placeholder {
            @media screen and (max-width: 600px) {
                font-size: 16px;
            }
        }

        &__alert {
            display: flex;
            flex-direction: column;
            width: 80%;
            margin: 3vh 0 0 0;
        }

        &__button {
            position: absolute;
            bottom: 5vh;
            left: 50%;
            transform: translateX(-50%);
            border: none;
            
            &_mobile {
                position: relative;
                margin: 15px 0 0 0;
                border: none;
            }
        }

        &__form {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}