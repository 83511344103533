.Styles {
  height: 30px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 6px var(--b-shadow-lighter);
  box-shadow: 0 0 6px var(--b-shadow-lighter);
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, var(--b-font-family-primary-1),
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.SquareLogo {
  width: 20px;
  margin-right: 5px;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: var(--b-font-family-primary-1), sans-serif;
}

.StatusContainer {
  background-image: var(--background-image);
  background-repeat: repeat-y;
  background-position: 45.5%;
  background-color: var(--b-color-text-primary-2);
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
  display: flex;
  z-index: 0;
}

.ClaimsHistoryContainer {
  background-color: var(--b-color-text-primary-2);
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
  display: flex;
  z-index: 0;
}

.TableContainer {
  background-color: var(--text-primary);
  margin: 0;
  padding: 10% 0 0 2%;
  width: 45%;
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
}

.FullContainer {
  background-color: var(--text-primary);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
}

.FullContainerReverse {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1100px) {
    display: block;
  }

  &.Mobile {
    display: flex !important;
  }
}

.QuestionsContainer {
  width: 53%;
}

.HeaderTable {
  font-family: var(--b-font-family-primary-1) !important;
  font-size: 25px !important;
  font-weight: bold !important;
  color: var(--b-color-text-primary-2) !important;
}

.ItemCountReverse {
  font-family: var(--b-font-family-primary-1);
  font-size: 25px;
  color: var(--text-primary);
}

.HeaderTableReverse {
  background-color: var(--b-color-main-1) !important;
  color: var(--b-color-text-primary-2) !important;
  font-size: 18px !important;
  line-height: 24px;
  border-top: 3px solid var(--b-color-bg-main-2);
  border-bottom: 3px solid var(--b-color-bg-main-2);
}

.HeaderTableReverse:first-child {
  border-radius: 13px 0px 0px 13px;
}

.HeaderTableReverse:last-child {
  border-radius: 0px 13px 13px 0px;
}

.HeaderTableReverse:not(:last-child) {
  border-right: 6px solid var(--b-color-bg-main-2);
}

.HeaderTableUser {
  border: none;
}

.CenterContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TableName {
  width: 120px;
}

.CellText {
  width: 100px;
}

.CellDescription {
  width: 250px;
}

.CellStatus {
  width: 300px;
}

.CellDigit {
  color: var(--b-color-text-primary-1);
  font-family: var(--b-font-family-primary-1);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.CellAmountRed {
  color: var(--b-color-main-1) !important;
}

.CellAmount {
  width: 70px;
}

@media (max-width: 1600px) and (min-width: 1301px) {
  .CellText {
    font-size: 12px;
    width: 50px;
  }

  .CellDescription {
    font-size: 12px;
    width: 175px;
  }

  .CellAmount {
    font-size: 12px;
    width: 70px;
  }
}

@media (max-width: 1300px) {
  .CellText {
    font-size: 11px;
    width: 50px;
  }

  .CellDescription {
    font-size: 11px;
    width: 175px;
  }

  .CellAmount {
    font-size: 11px;
    width: 70px;
  }

  .CellStatus {
    font-size: 11px;
    width: 50px;
  }
}

.SubTotal {
  display: flex;
  align-items: flex-end;
}

.SubTotalText {
  margin-left: 5px;
  font-family: var(--b-font-family-secondary-1);
}

.White {
  color: var(--b-color-text-primary-2);
}

.InfoAbgelehnt {
  cursor: pointer;
}

.ContainerBetrag {
  font-family: var(--b-font-family-primary-1);
  font-size: 25px;
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  transition: 1s ease-in all;
}

.ImgBetrag {
  width: 200px;
  margin-right: 50px;
}

.GreyScrollbar::-webkit-scrollbar-track {
  margin: 5px;
  -webkit-box-shadow: inset 0 0 6px var(--b-shadow-lighter);
  border-radius: 10px;
  background-color: var(--b-color-text-primary-2);
}

.GreyScrollbar::-webkit-scrollbar {
  margin: 5px;
  width: 9px;
  background-color: unset;
}

.GreyScrollbar::-webkit-scrollbar-thumb {
  margin: 5px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--b-shadow-lighter);
  background-color: var(--b-color-bg-additional-2);
}

.Type {
  font-family: var(--b-font-family-secondary-1);
  color: var(--text-primary);
  background-color: var(--b-color-text-primary-2);
  border-radius: 5px;
  font-size: smaller;
  padding: 2px 4px;
}

.HelpUs {
  font-size: smaller;
}

.Button {
  font-family: var(--b-font-family-secondary-1);
  background-color: var(--b-color-text-primary-2);
  color: var(--text-primary);
  border-radius: 5px !important;
  border: solid 2px var(--text-primary);
  padding: 8px 16px !important;
  text-align: center;
  cursor: pointer;
  box-shadow: var(--b-shadow-lighter) 0px 5px 15px !important;
  font-size: 14px;
  margin: 10px;
  align-self: center;
}

.ButtonReverse,
.ButtonRegular {
  font-family: var(--b-font-family-primary-1);
  border-radius: 5px !important;
  border: solid 3px var(--text-primary);
  padding: 8px 16px !important;
  text-align: center;
  box-shadow: var(--b-shadow-lighter) 0px 5px 15px !important;
  font-size: 14px;
  margin: 10px;
  align-self: center;
  color: var(--b-color-text-primary-2);
  cursor: not-allowed;
  display: flex;
  justify-content: center;
}

.ButtonSelection {
  font-family: var(--b-font-family-secondary-1);
  border-radius: 5px !important;
  border: solid 3px var(--text-primary);
  padding: 32px 48px !important;
  text-align: center;
  box-shadow: var(--b-shadow-lighter) 0px 5px 15px !important;
  font-size: 24px;
  margin: 10px;
  align-self: center;
  color: var(--b-color-text-primary-2);
  cursor: not-allowed;
  display: flex;
  justify-content: center;
  width: 300px;
  height: 100px;
  align-items: center;
}

.Count {
  font-size: 28px;
}

.CountContainer {
  padding: 10px;
  font-size: 18px;
  align-self: center;
  margin-right: auto;
  font-variant-numeric: lining-nums proportional-nums;
}

.NavContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 0 0 20px;
}

.Enabled {
  background-color: var(--text-primary);
  cursor: pointer;
}

.Enabled:hover {
  background-color: var(--b-color-text-primary-2) !important;
  color: var(--text-primary) !important;
}

.ButtonTransparent {
  font-family: var(--b-font-family-primary-1);
  color: var(--text-primary) !important;
  padding: 15px 0 !important;
  text-align: center;
  cursor: pointer;
  font-size: 30px;
  margin: 0;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  @media screen and (orientation: portrait) and (max-width: 600px) {
    font-size: 24px;
  }
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  width: 30%;
}

.Distance {
  width: 600px;
}

.Close {
  width: 60px;
  float: right;
  margin: 10px 10px 0 0;
  text-align: center;
}

.QuestionContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  text-align: center;
  float: right;
  /* margin-top: 50px; */
}

.HalfQuestionContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 48%;
  text-align: center;
  float: right;
  /* margin-top: 50px; */
}

@media screen and (max-width: 800px) {
  .Distance {
    width: 0px;
  }
}

@media (max-width: 1199px) {
  .QuestionContainer {
    /* width: 100%; */
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    font-size: 0.8em;
  }
}

.NavBtnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
  position: relative;
  margin: 30px 0 0 0;
  align-self: center;
}

.NavButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 90px;
  background-color: var(--b-color-bg-main-2);
  z-index: 5;
  margin-bottom: 100px;

  @media screen and (min-width: 1000px) {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 920px) {
  .NavButtonContainer {
    top: 128px;
  }

  div.NavButtonContainer.NavButtonContainer---sticky {
    top: 128px;
  }
}

// last version of nav btns on top

// .NavButtonContainer.NavButtonContainer::before {
//   content: "";
//   position: absolute;
//   left: -20vw;
//   height: 100%;
//   width: 120vw;
//   z-index: -1;
//   background: var(--b-color-bg-main-2);
// }

// old version of nav btns

// .NavButtonContainer.NavButtonContainer---sticky {
//   /* position: fixed; */
//   /* top: 90px; */
//   /* background-color: var(--b-color-bg-main-2); */
//   /* z-index: 2; */
// }

/* .NavButtonContainer.NavButtonContainer---sticky::before {
  content: "";
  position: absolute;
  left: -20vw;
  height: 100%;
  width: 120vw;
  background: var(--b-color-bg-main-2);
} */

.NavButtonContainer_initial {
  /* background-color: initial; */
}

.DisabledButton {
  opacity: 50% !important;
  pointer-events: none;
}

.Tooltip {
  color: var(--b-color-main-2);
  font-size: small;
}

.AnswersContainer {
  font-family: var(--b-font-family-secondary-1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  /* margin: 10px 0 20px 0; */
  font-size: 16px;
  width: 100%;
}

.AnswersContainerUpload {
  /* padding: 50px 0 40px 0; */
  align-items: center;
}

.AnswersRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  align-items: center;
}

.AnswersRow.AnswersRow--tripple {
  grid-template-columns: auto 1fr 1fr;
}

@media screen and (max-width: 800px) {

  .AnswersRow,
  .AnswersRow.AnswersRow--tripple {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.AnswersContainer>div {
  margin-top: 6px;
}

.TitleQuestion {
  display: flex;
  color: var(--b-color-text-primary-1);
  font-family: var(--b-font-family-primary-1);
  font-size: 18px;
  font-weight: 600;
  font-variant-numeric: lining-nums proportional-nums;
  text-align: center;
  align-self: center;
}

@media (min-width: 800px) {
  .TitleQuestion {
    font-size: 24px;
    text-align: left;
    align-self: flex-start;
  }
}

.TooltipGap {
  margin-left: 10px;
}

.DescriptionQuestion {
  display: flex;
  font-family: var(--b-font-family-primary-1);
  font-size: 14px;
  text-align: start;
}

.AnswerQuestion {
  background-color: var(--text-primary);
  color: var(--b-color-text-primary-2);
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  margin: 5px;
  box-shadow: var(--b-shadow-lighter) 0px 5px 15px;
}

.MenuTitle {
  font-family: var(--b-font-family-secondary-1);
  font-size: 16px;
  margin: 10px 0 0 10px;
}

.NameTitle {
  font-family: var(--b-font-family-primary-1);
  font-size: 40px;
  color: var(--text-primary);
  margin-left: 2px;
}

.OpenMenu {
  position: absolute;
  left: 15px;
  top: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InvoiceLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.WelcomeContainer {
  /* width: 100vw; */
  /* height: 100vh; */
  overflow-x: hidden;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  z-index: 0;
  justify-content: center;
  align-items: center;
}

.MenuTitleContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MenuContainer {
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.MenuElements {
  background-color: var(--text-primary);
  border: solid thick var(--text-primary);
  color: var(--b-color-text-primary-2);
  border-radius: 10px;
  padding: 35px;
  cursor: pointer;
  margin: 35px;
  box-shadow: var(--b-shadow-lighter) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 170px;
  justify-content: center;
}

.BtnOutlined {
  background-color: var(--b-color-text-primary-2);
  color: var(--text-primary);
  border: solid 1px var(--text-primary);
  border-radius: 5px;
  cursor: pointer;
  box-shadow: var(--b-shadow-lighter) 0px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 10px 0;
  padding: 10px 20px;
  transition: all 0.3s;
  width: 100%;
}

.BtnOutlined:hover,
.BtnOutlinedActive {
  background-color: var(--text-primary);
  color: var(--b-color-text-primary-2);
}

.MenuElementsColors {
  color: var(--b-color-text-primary-2);
  padding: 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 74px;
}

.MenuElements:hover {
  background-color: var(--b-color-text-primary-2);
  color: var(--text-primary);
}

.MenuElementName {
  font-family: var(--b-font-family-secondary-1);
  font-size: 16px;
  text-align: center;
}

.ThemeElement {
  height: 40px;
  margin: 25px 0px;
}

@media (max-width: 750px) {
  .MenuContainer {
    flex-direction: column;
  }
}

@media (max-height: 630px) {
  .MenuContainer {
    flex-direction: row;
  }

  .MenuElementsColors {
    padding: 5px;
  }

  .Styles {
    padding: 13px;
  }

  .MenuElements {
    height: 120px;
  }

  .ThemeElements {
    margin: 5px;
  }

  .ThemeElement {
    height: 30px;
    margin: 10px 0px;
  }
}

@media (max-width: 750px) and (max-height: 630px) and (min-height: 460px) {
  .MenuContainer {
    flex-direction: row;
  }

  .MenuElementsColors {
    border: solid thin var(--text-primary);
    padding: 5px;
    margin: 5px;
  }

  .Styles {
    padding: 13px;
  }

  .MenuElements {
    height: 120px;
  }

  .ThemeElements {
    margin: 5px;
  }

  .ThemeElement {
    height: 30px;
    margin: 10px 0px;
  }
}

@media (max-width: 750px) and (max-height: 460px) {
  .MenuContainer {
    flex-direction: row;
  }

  .MenuElementsColors {
    border: solid thin var(--text-primary);
    padding: 5px;
    margin: 5px;
  }

  .Styles {
    padding: 13px;
  }

  .ThemeElements {
    margin: 5px;
  }

  .ThemeElement {
    height: 30px;
    margin: 10px 0px;
  }

  .MenuElements {
    padding: 15px;
    margin: 35px;
    width: 70px;
  }

  .MenuElementName {
    display: none;
  }
}

.SlowShow {
  animation: 1.5s fadeInAnimation;
  animation-fill-mode: forwards;
  visibility: hidden;
  z-index: 1;
}

.FastShow {
  animation: 0.5s fadeInAnimation;
  animation-fill-mode: forwards;
  visibility: hidden;
  // z-index: 1;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

.MenuElementAdd {
  color: var(--text-primary);
  border: solid thick var(--text-primary);
  font-family: var(--b-font-family-secondary-1);
  font-size: 32px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.MenuElementAdd:hover {
  background-color: var(--text-primary);
  color: var(--b-color-text-primary-2);
}

.MenuTitle {
  color: var(--text-primary);
  font-family: var(--b-font-family-secondary-1);
  font-size: 32px;
  text-align: center;
}

.TopRightInfo {
  position: absolute;
  display: flex;
  align-items: center;
  right: 40px;
  top: 10px;
  z-index: 2;
  height: 40px;
  margin: 10px 0 0 10px;
  color: var(--text-primary);
  font-family: var(--b-font-family-secondary-1);
}

.TopLeftIcons {
  position: absolute;
  display: flex;
  align-items: center;
  left: 10px;
  top: 10px;
  z-index: 2;
  height: 40px;
  margin: 10px 0 0 10px;
}

.TopLeftIcons button {
  cursor: pointer;
  height: 100%;
  margin: 0;
}

.TopLeftIcons button:not(:last-child) {
  margin-right: 20px;
}

.Menu {
  position: absolute;
  top: 130%;
  left: 0;
  height: 0;
  transition: all 0.3s;
  opacity: 0;
  width: 100%;
  background: var(--b-color-text-primary-2);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: var(--b-shadow-lighter) 0px 5px 10px;
}

.MenuItem {
  padding: 10px;
  cursor: pointer;
}

.MenuItem:hover {
  color: var(--b-color-text-primary-2);
  background: var(--background_2);
}

.MenuItem:not(:last-child) {
  border-bottom: 0.5px solid var(--background_2);
}

.MenuOpened {
  height: fit-content;
  opacity: 1;
}

.StatisticsItem {
  color: var(--b-shadow-main);
  font-family: var(--b-font-family-secondary-1);
}

.StatisticsItem span {
  color: var(--background_2);
  font-family: var(--b-font-family-secondary-1);
}

.SaveChanges {
  position: absolute;
  right: 45px;
  bottom: 30px;
  padding: 4px 0 0 5px;
  border-radius: 50%;
  background-color: var(--b-color-text-primary-2);
  height: 35px;
  width: 35px;
  box-shadow: var(--b-shadow-lighter) 0px 5px 15px;
  border: var(--text-primary) solid thin;
  color: var(--text-primary);
  cursor: pointer;
}

.TableQuoteDetails {
  width: 90% !important;
  height: 90vh;
  margin-left: 6%;
}

.HeaderTableQuoteDetails {
  color: var(--text-primary) !important;
  background-color: var(--b-color-text-primary-2) !important;
  font-family: var(--b-font-family-secondary-1) !important;
  font-size: 16px !important;
}

.ListQuotesDetails {
  margin-top: 3px;
  color: var(--text-primary);
}

.DeleteListElement {
  color: var(--text-primary);
  font-family: var(--b-font-family-secondary-1);
  margin-left: 5px;
  cursor: pointer;
}

.AddListElement {
  color: var(--text-primary);
  font-family: var(--b-font-family-secondary-1);
  margin-left: 5px;
  cursor: pointer;
  font-size: 24px;
}

.DivsQuotesDetails {
  margin-top: 10px !important;
  width: 80%;
  background-color: var(--b-color-text-primary-2);
}

.NewQuoteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-primary);
  font-family: var(--b-font-family-secondary-1);
  font-size: 24px;
  margin-top: 20px;
}

.NewQuoteName {
  width: 50%;
  color: var(--text-primary);
  font-family: var(--b-font-family-secondary-1);
  font-size: 24px;
  margin-right: 10px;
}

.RedScrollbar::-webkit-scrollbar-track {
  margin: 0 5px 0 5px;
  -webkit-box-shadow: inset 0 0 6px var(--text-primary) 2f;
  border-radius: 10px;
  background-color: var(--b-color-bg-additional-1);
}

.RedScrollbar::-webkit-scrollbar {
  margin: 0 5px 0 5px;
  width: 9px;
  background-color: var(--b-color-bg-additional-1);
}

.RedScrollbar::-webkit-scrollbar-thumb {
  margin: 0 5px 0 5px;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--b-shadow-lighter);
  background-color: var(--text-primary);
}

.LoadingContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--b-font-family-secondary-1);
  color: var(--text-primary) !important;
  min-width: unset;
  overflow: hidden;
  align-self: center;
}

.LoaderGif {
  width: 50%;
}

.Loading {
  color: var(--text-primary) !important;
  width: 75px !important;
  margin: 30vh 0 30px 0;
}

.SelectContainer {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.FMContainer,
.FMContainerCreate,
.FMContent {
  width: 70%;
  margin: 0 auto;
  height: 100vh;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  z-index: 0;
  justify-content: flex-end;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: 90%;
  }
}

.AuditContent {
  width: 96%;
  margin: 0 auto;
  overflow-y: visible;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  z-index: 0;
}

.AuditGroup {
  margin: 20px;
}

.PreventionDocWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.DuplicateDocWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.ActionBtnBlock {
  display: flex;
  flex-direction: row;
  margin: 0 0 10px 0;
}

.DuplicateFinish {
  margin: 0 auto 0 auto;
}

.HeaderDuplicates {
  color: var(--text-primary);
  padding: 20px;
  font-family: var(--b-font-family-primary-1) !important;
  font-size: 25px !important;
  font-weight: bold !important;
}

.HeaderContainerDuplicates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// .PreviewBlock {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// }

.PdfDoc {
  border: var(--primary-color) solid thin;
  width: 40vw;
  position: relative;
  overflow: hidden;
  height: 70vh;
}

.PreventionImg {
  border: var(--primary-color) solid thin;
  height: 100%;
  margin: 20px;
  text-align: center;
  vertical-align: center;
}

.RecognizedTriggers {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 15px;
  overflow-y: auto;
}

.RecognizedTriggers div:first-child {
  align-self: center !important;
  font-size: 20px;
}

.RecognizedTriggers div {
  font-weight: 600;
}

.RecognizedTriggers li {
  font-weight: 400;
}

.checkDoc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  vertical-align: center;
}

.inputsOCR {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-self: flex-start;
  align-items: flex-start;
}

.inputsTotal {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  flex-direction: row;
}

.inputTitle {
  align-self: flex-start;
  padding: 10px 5px 5px 5px;
  font-size: 1rem;
  font-weight: 700;
}

.AuditContent .FMContent {
  width: 100%;
  height: auto;
}

.FMContainer {
  width: 100%;
  height: auto;
}

.FMContainerCreate {
  width: 100vw;
  min-width: 100%;
  padding: 0 30px 130px;
  margin: 0;
  height: fit-content;
}

.ZoomContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.FMContainerLoading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FMHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-primary);
  padding: 20px;
  font-family: var(--b-font-family-primary-1) !important;
  font-size: 25px !important;
  font-weight: bold !important;
}

.DuplicateWrap {
  width: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
}

@media (max-width: 500px) {
  .FMHeader-wrapper {
    margin-bottom: 200px;
  }

  .FMHeader-wrapper_mobile {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .FMHeader-wrapper_mobile .FMHeader {
    display: block;
    text-align: center;
  }
}

.FMTable {
  margin-bottom: 50px;
}

.FlowStepsItem {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-bottom: 20px;
}

.FSITitle {
  margin: 10px 0;
  font-family: var(--b-font-family-secondary-1);
  color: var(--text-secondary);
  font-size: 16px;
}

.FSIGeneral .BasicInput:first-child .FSITitle {
  margin: 0 0 10px 0;
}

.FSIHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--background_2);
  font-family: var(--b-font-family-secondary-1);
  color: var(--text-secondary);
  font-size: 16px;
}

.FSIGeneral {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0 20px 0;
}

.FSIHeader__close {
  cursor: pointer;
}

.FSItemsHolder {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--background_2);
  width: fit-content;
  min-width: 300px;
  margin-bottom: 50px;
  -webkit-box-shadow: 1px 4px 10px var(--b-shadow-lighter);
  -moz-box-shadow: 1px 4px 10px var(--b-shadow-lighter);
  box-shadow: 1px 4px 10px var(--b-shadow-lighter);
  background-color: var(--b-color-text-primary-2);
}

.FlowHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  background-color: var(--b-color-text-primary-2);

  @media screen and (min-width: 600px) {
    min-width: 300px;
  }
}

.SubflowHolder {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.SubflowHolder .FSItemsHolder {
  display: flex;
  flex-basis: 300px auto;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
}

.SubflowHolder::-webkit-scrollbar {
  margin: 5px;
  width: 9px;
  height: 9px;
  background-color: unset;
}

.SFTitle {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: var(--background_2);
  font-family: var(--b-font-family-secondary-1);
}

.FSEnd {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
}

@media (max-width: 500px) {
  .FSEnd {
    margin-top: 200px;
  }
}

.FSBtn {
  cursor: pointer;
}

.FSEndOptions {
  position: absolute;
  /* top: -140%; */
  opacity: 0;
  z-index: -10;
  transition: all 0.3s;
  border: 0.5px solid var(--background_2);
  border-radius: 10px;
  overflow: hidden;
}

.FSEndOptions--active {
  z-index: 0;
  opacity: 1;
}

.FSEndOptionsItem {
  padding: 5px 10px;
  color: var(--background_2);
  background-color: var(--b-color-text-primary-2);
  transition: all 0.3s;
}

.FSEndOptionsItem:hover {
  color: var(--b-color-text-primary-2);
  background-color: var(--background_2);
  cursor: pointer;
}

.SelectInvoice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;

  padding: 24px 50px;
  justify-self: center;
  width: 100%;

  border-radius: 13px;
  border: 3px dashed var(--b-color-additional-1);
  background: var(--b-color-bg-main-2);

  position: relative;
  margin-top: 50px;
}

.SelectInvoice button {
  background: var(--b-color-main-2) !important;
  border-radius: 14px !important;
}

.SelectInvoice button:hover {
  background: var(--b-color-main-2) !important;
}

.SelectComponent {
  background-color: var(--b-color-text-primary-2) !important;
}

.deleteImage {
  background-color: var(--text-primary) !important;
  font-family: var(--b-font-family-secondary-1);
  font-size: 12px !important;
}

.uploadPictureContainer {
  background-color: var(--text-primary) 57 !important;
  border-color: var(--text-primary) 57 !important;
  width: 35% !important;
}

.AnalyzeButton {
  width: 300px;
  margin: 0 auto 25px auto;
}

.Select {
  font-size: 18px;
  border-radius: 5px;
  font-family: var(--b-font-family-secondary-1) !important;
  color: var(--text-primary) !important;
  width: 100%;
  cursor: pointer;
}

.Separator {
  width: 20px;
}

.InvoiceTableCointainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-items: center;
}

.InvoiceTableTop {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  justify-items: center;
  padding: 0 12px;
}

.InvoiceTableInfo {
  font-size: 12px;
  border-radius: 5px;
  border: thin solid var(--text-primary);
  margin: 10px 0px;
  padding: 10px;
  width: 97%;
  -webkit-box-shadow: 0 0 6px var(--b-shadow-lighter);
}

@media screen and (max-width: 800px) {
  .InvoiceTableTop {
    grid-template-columns: 1fr;
  }

  .InvoiceTableInfo {
    width: 90%;
  }
}

.InfoTitle {
  font-family: var(--b-font-family-secondary-1);
  font-weight: bold;
  color: var(--text-primary);
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.Pointer {
  cursor: pointer;
}

.InvoiceTable {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.InvoiceTableApprove {
  margin: 25px;
  width: 250px;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px var(--b-color-main-1);
  border: solid 3px transparent;
  border-radius: 1rem;
}

::-webkit-scrollbar-track {
  border: solid 7px var(--b-color-text-primary-2);
  box-shadow: inset 0 0 10px 10px var(--b-color-bg-additional-1);
  border-radius: 1rem;
}

.Actions {
  display: flex;
  width: 50px;
  justify-content: space-around;
}

.Cells {
  padding: 10px 4px !important;
  vertical-align: middle !important;
}

.CellsSlim {
  padding: 4px 16px !important;
  vertical-align: middle !important;
}

.DateSelector {
  padding: 0px !important;
}

.PetsName,
.Admins,
.DiagnosisModal {
  font-family: var(--b-font-family-secondary-1);
  color: var(--text-primary);
  font-size: 16px;
}

.DescriptionModal {
  font-family: var(--b-font-family-secondary-1);
  color: var(--b-shadow-main);
  font-size: 10px;
}

.InformationReview {
  font-style: italic;
  text-decoration: underline;
  color: var(--text-primary);
  text-align: right;
}

.TotalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}

.TotalGroup {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.TotalSubGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TotalTitle {
  font-family: var(--b-font-family-secondary-1);
  color: var(--text-primary);
  font-size: 16px;
  width: 150px;
}

.TotalAmount {
  font-family: var(--b-font-family-secondary-1);
  color: var(--b-shadow-main);
  font-size: 16px;
  width: 100px;
  text-align: left;
}

.Amount {
  font-family: var(--b-font-family-secondary-1);
  color: var(--b-shadow-main);
  font-size: 16px;
  text-align: right;
}

.Add {
  font-family: var(--b-font-family-secondary-1);
  color: var(--b-shadow-main);
  font-size: 16px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.AddText {
  margin-left: 5px;
}

.MarginBottom {
  margin-bottom: 10px;
}

.ModalVendor {
  max-height: 80%;
  margin: 5% 0 5% 0;
  overflow-y: auto;
}

@media (max-width: 500px) {
  .ModalVendor {
    width: auto !important;
  }
}

.ModalCustom {
  // overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
}

.header-spacer::-webkit-scrollbar {
  width: 0px;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.ModalGOT {
  height: 80%;
  margin: 5% 0 5% 0;
  overflow-y: visible;
}

.TitleModal {
  font-family: var(--b-font-family-secondary-1);
  color: var(--b-shadow-main);
  font-size: 12px;
  margin: 10px 0px 10px 0px;
}

.ButtonUpdate {
  margin: 10px 0px;
  width: 200px;
}

.ButtonCenter {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.ButtonRight {
  margin-left: auto !important;
}

.ClientLogo {
  width: 40px;
}

.DiagnosisContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-top: 25px;
}

.DiagnosisInfo {
  border: thin solid var(--b-shadow-main);
  background-color: var(--b-color-text-primary-1);
  -webkit-box-shadow: 0 0 6px 0 var(--b-shadow-lighter);
  border-radius: 2px;
  margin: 20px 10px 20px 10px;
  padding: 20px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: small;
}

.DiagnosisInfoGroup {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.DiagnosisInformationWrapper {
  display: flex;
  align-items: center;
  color: var(--text-primary);
  margin-bottom: 15px;
}

.DiagnosisInformation {
  font-weight: bold;
}

.DiagnosisInfoTitle {
  font-family: var(--b-font-family-secondary-1);
  color: var(--text-primary);
  font-weight: bold;
}

.OtherCategories {
  display: flex;
}

.CategoriesDiagnosisContainer {
  width: 100%;
}

.CategoriesDiagnosis {
  font-style: italic;
  color: var(--b-shadow-main);
  margin: 10px 0px;
  padding: 5px 0px;
  border-bottom: var(--b-color-text-primary-1) solid thin;
  width: 100%;
  text-align: left;
  font-size: 10px;
}

.GotNumberContainer {
  display: flex;
  font-family: var(--b-font-family-secondary-1);
  color: var(--text-primary);
}

.GotNumber {
  margin-right: 5px;
  font-weight: bold;
}

.GotBezeichnung {
  font-style: italic;
  font-size: 10px;
  margin-bottom: 10px;
  color: var(--b-shadow-main);
}

.GotNumberWrapper:hover {
  background-color: var(--text-secondary);
  border: solid thin var(--text-primary);
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
}

.GotSelected {
  border-bottom: solid thin var(--b-color-bg-main-1);
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.GotNotSelected {
  font-style: italic;
  font-size: 12px;
}

.GotList {
  height: 60vh;
  overflow-y: scroll;
}

.GotNumberWrapperSelected {
  background-color: var(--text-secondary);
  border: solid thin var(--text-primary);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.ItemizedView {
  padding: 5px;
  cursor: pointer;
}

.ItemizedView td {
  font-family: var(--b-font-family-secondary-1) !important;
}

.ItemizedViewModal {
  padding: 5px;
}

.HoverRow:hover {
  color: var(--text-primary);
  font-family: var(--b-font-family-secondary-1);
  background-color: var(--b-color-text-primary-1);
  -webkit-box-shadow: 0 0 6px 0 var(--b-shadow-lighter);
}

.HoverRow:last-child,
.ItemizedView:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.ItemizedView:last-child td:first-child {
  border-bottom-left-radius: 20px;
}

.ItemizedView td:first-child {
  border-left: 2px solid var(--primary-color);
}

.ItemizedView td:last-child {
  border-right: 2px solid var(--primary-color);
}

.ItemizedView:last-child td {
  border-bottom: 2px solid var(--primary-color);
}

.ItemizedView:last-child td:last-child {
  border-bottom-right-radius: 20px;
}

.ItemizedView td:not(:first-child) {
  border-left: 1px solid var(--b-shadow-main);
}

.RestyledTableRow {
  cursor: pointer;
}

.RestyledTableRow:hover>td {
  background-color: var(--b-color-bg-main-1) !important;
}

.RestyledTableRow td {
  background-color: var(--b-color-bg-main-2);
  border-top: 3px solid var(--b-color-bg-main-2);
  border-bottom: 3px solid var(--b-color-bg-main-2);
}

.RestyledTableRow.InvoiceTable td {
  background-color: var(--b-color-bg-additional-4);
  border-top: 3px solid var(--b-color-bg-main-2);
  border-bottom: 3px solid var(--b-color-bg-main-2);
}

.RestyledTableRow.EditOpacity td {
  opacity: 0.5 !important;
  background-color: var(--b-color-bg-additional-4);
}

.RestyledTableRow.EditRowsMode td {
  background-color: var(--b-color-additional-1);
  color: var(--b-color-bg-main-2) !important;
}

.RestyledTableRow.EditRowsMode:hover>td {
  color: var(--b-color-additional-1) !important;
}

.RestyledTableRow td:first-child {
  border-bottom-left-radius: 13px;
  border-top-left-radius: 13px;
}

.RestyledTableRow td:last-child {
  border-bottom-right-radius: 13px;
  border-top-right-radius: 13px;
}

.RestyledTableRow td:not(:last-child) {
  border-right: 6px solid var(--b-color-bg-main-2);
}

.TableClaimsContainerModal {
  width: 100%;
  height: 100%;
  max-height: 300px;
}

.CenterDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ViewImage {
  margin-left: 10px;
  cursor: pointer;
}

.FMTableSvg {
  margin-left: 23px;
  cursor: pointer;
}

.Status {
  color: var(--b-color-text-primary-2);
  background-color: var(--text-primary);
  border-radius: 5px;
  padding: 3px;
  text-align: center;
  font-size: 10px;
}

.StatusInverted {
  color: var(--text-primary);
  background-color: var(--b-color-text-primary-2);
  border: solid 2px var(--text-primary);
  box-shadow: var(--b-shadow-lighter) 0px 5px 15px !important;
  border-radius: 5px;
  padding: 3px;
  text-align: center;
  font-size: 10px;
}

.StatusInverted:hover {
  background-color: var(--text-primary);
  color: var(--b-color-text-primary-2);
}

/* LOGIN PAGE START */

.login-holder {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.login-holder__left {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--b-color-text-primary-2);
}

.left__header {
  display: flex;
  align-items: center;
  margin: 50px 50px 0px 50px;
}

.left__header__icon {
  width: 32px;
  height: 32px;
}

.left__header__title {
  margin: 0 0 0 10px;
}

.left__form {
  margin: 32px 50px 0px 50px;
}

.left__form p {
  margin: 0;
  font-size: 13px;
  color: var(--b-shadow-main);
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.left__form label div {
  margin: 5px 0 10px;
  width: 100%;
}

.forgot-holder {
  display: flex;
  justify-content: flex-end;
}

.forgot-holder span,
.left__actions span {
  font-size: 14px;
  color: var(--background_2);
  cursor: pointer;
  margin-bottom: 40px;
}

.login-holder__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  min-height: 100vh;
  padding: 60px;
  position: relative;
  box-sizing: border-box;
  background: var(--background_2);
}

.left__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--b-color-text-primary-1);
  font-size: 14px;
}

.left__actions button {
  margin: 0;
}

.right__img {
  position: absolute;
  right: 0;
  bottom: 5%;
  width: 55%;
}

.right__text {
  max-width: 50%;
  z-index: 5;
  color: var(--b-color-text-primary-2);
}

.right__text h1 {
  margin: 0;
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
}

.right__text p {
  font-size: 16px;
  line-height: 24px;
  padding: 24px 0 0;
  margin: 0;
}

.right__text div {
  display: flex;
  align-items: center;
}

.right__text .right__text__more {
  width: fit-content;
  cursor: pointer;
  padding-bottom: 2px;
  font-weight: bold;
  margin-top: 25px;
}

.MoreLink {
  padding: 0;
  margin: 0 10px 0 0;
  color: var(--text-secondary);
  transition: all 0.3s;
}

.MoreLink:hover {
  opacity: 0.7;
}

.BasicInput {
  position: relative;
  transition: border-color 150ms ease-in-out 0s;
}

.ModalAdmins {
  display: flex;
  flex-direction: column;
}

.BasicInput div {
  height: 40px;
  padding: 4px 10px;
}

.BasicInput div::before,
.BasicInput div::after {
  content: none;
}

.BasicInput img {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 7px;
  top: 16px;
}

.PolicyInput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 30px 0;
}

.OQInput {
  width: 100%;
  border: thin solid var(--b-color-bg-additional-2);
  padding: 4px 4px 5px 4px;
  font-size: 12px;
  border-radius: 5px;
  background-color: var(--b-color-text-primary-2);
}

.TitleInput {
  width: 100%;
  border: thin solid var(--b-color-bg-additional-2);
  padding: 4px 4px 5px 4px;
  font-size: 12px;
  border-radius: 5px;
  background-color: var(--b-color-text-primary-2);
}

.TitleInputDisabled {
  border: 2px solid var(--b-color-main-2);
  color: var(--b-color-main-1) !important;
}

/* LOGIN PAGE END */

.ModalDocument,
.ModalHolder__main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--b-color-text-primary-1);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalDocumentPDF {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.ModalHolder {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  height: 90%;

  padding: 20px;
  background: var(--b-color-bg-main-1);
  border-radius: 25px;
  box-shadow: 10px 10px 10px var(--b-color-text-primary-1);

  @media screen and (min-width: 600px) {
    padding: 35px;
    border-radius: 45px;
  }
}

.ModalHolder .ModalPage::-webkit-scrollbar-track {
  border: 0;
}

.ModalSmall {
  width: 40%;
  height: auto;
}

.ModalSmall .ModalHeader {
  margin-bottom: 0;
}

.ModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 0.5px solid var(--b-color-text-primary-1);
  padding: 20px;
}

.ModalHeader button {
  transition: all 0.3s;
  border: none;
  background: none;
  cursor: pointer;
}

.ModalHeader button:hover {
  opacity: 0.5;
}

.ModalPage,
.ModalBody {
  overflow: scroll;
  /* padding: 30px; */
}

.ModalPage {
  min-width: unset !important;
  min-height: unset !important;
}

.ModalBody {
  height: inherit;
  padding: 0 30px;
}

.ModalForm {
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ModalForm>label {
  width: 100%;
}

.ModalForm div {
  width: 100%;
}

.ModalForm button {
  margin: 25px 0 0;
  align-self: flex-end;
}

.ButtonDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.ButtonDisabledRed {
  pointer-events: none;
  opacity: 0.5;
  color: var(--b-color-main-2);
}

.WelcomeContainerMain {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.WelcomeContainerMain .MenuContainer {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.WelcomeContainerMain .MenuContainer .MenuElements {
  width: 45%;
  min-height: 100px;
  height: auto;
  padding: 10px;
  margin: 0;
}

.WelcomeContainerMain .ContentHolder {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.WelcomeRight {
  display: flex;
  flex-direction: column;
}

.DocumentHolder {
  max-height: 60vh;
  overflow: scroll;
}

.Footer {
  width: 100vw;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--b-shadow-main);
  background-color: var(--b-color-bg-additional-5);
  border-top: 1px solid var(--b-color-bg-additional-5);
}

.Footer p {
  margin: 20px;
}

.MainContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* MOBILE start */

@media only screen and (max-width: 1140px) {
  .ContentHolder {
    width: 90% !important;
  }
}

@media only screen and (max-width: 870px) {
  .login-holder {
    flex-direction: column;
    align-items: center;
  }

  .login-holder__left {
    width: 80%;
    align-items: center;
  }

  .login-holder__right {
    width: 100%;
    min-height: unset;
    align-items: center;
  }

  .MenuElementsColors {
    width: 50vw;
    position: fixed;
    left: calc(25vw);
    bottom: 0;
  }

  .right__text {
    max-width: unset;
  }

  .right__img {
    position: static;
    width: 80%;
  }

  .right__text__more {
    justify-content: flex-end;
    width: 100% !important;
    margin: 0 0 25px !important;
  }

  .left__form {
    width: 100%;
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .BasicInput,
  .left__username,
  .left__password {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .left__form label div {
    margin: 5px 0 15px;
  }

  .ContentHolder {
    flex-direction: column;
  }

  .WelcomeRight {
    margin-top: 50px;
    width: 100%;
  }

  .Statistics {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 670px) {
  .Statistics {
    flex-direction: column;
  }
}

@media only screen and (max-width: 670px) {
  .MenuElements {
    min-height: 140px !important;
  }
}

@media only screen and (max-width: 250px) {
  .MenuContainer {
    flex-direction: column !important;
  }

  .MenuElements {
    width: 100% !important;
    min-height: 120px !important;
    margin-bottom: 20px !important;
  }
}

/* MOBILE finish */

/* 404 page */
.ErrorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--b-font-family-primary-1);
  font-family: var(--b-font-family-secondary-1);
}

.ErrorPage img {
  height: 80vh;
}

.MCQHolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.MCQ {
  display: flex;
  flex-wrap: wrap;
  height: fit-content !important;
  width: 100%;
  padding: 0 20px !important;
}

.MCQItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: var(--background_2);
  background: var(--b-color-text-primary-2);
  border: 1px solid var(--background_2);
  border-radius: 5px;
  margin: 5px 15px 5px 0;
  transition: all 0.3s;
}

.MCQCreate {
  padding: 0 !important;
  height: initial;
  display: flex;
  align-items: center;
  width: 50%;
  margin: 5px 15px 5px 0;
}

.MCQItem__text {
  margin: -2px 5px 0 0;
}

.Upload-content {
  margin-top: 10px;
}

.MCQItem__close {
  cursor: pointer;
}

.MCQBtn {
  margin: 15px 15px 15px 0;
  padding: 0 !important;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.MCQBtn>svg {
  width: 100%;
  height: 100%;
}

/* Toast styles */
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--text-primary) !important;
  background-color: var(--b-color-text-primary-2) !important;
  font-family: var(--b-font-family-secondary-1) !important;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
  background-color: var(--background_2) !important;
}

.DateRange {
  display: flex;
  justify-content: space-between;
}

.DateRange>div {
  width: 48%;
}

.LinkBasic {
  color: var(--text-primary);
  transition: all 0.3s;
  text-decoration: none;
}

.LinkBasic:hover {
  opacity: 0.7;
}

.ButtonMCQAnswer {
  font-size: 18px;
  width: 300px;

  @media screen and (max-width: 600px) {
    width: auto;
    font-size: 16px;
  }
}

.NoTableData {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--b-color-text-primary-1);
  font-family: RooneySans;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}

.ButtonsGroupTopRight {
  position: fixed;
  right: 25px;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  z-index: 2;
  border: solid var(--text-primary);
  background-color: var(--text-primary);
}

@media screen and (max-width: 800px) {
  .ButtonsGroupTopRight {
    position: static;
  }
}

.Top25 {
  top: 25px;
}

.ZoomFixed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 25px;
  right: calc(50vw - 150px);
  width: 300px;
}

.FullWidth {
  width: 90%;
}

.ZoomTitle {
  color: var(--text-secondary);
}

.ContainerFlow {
  background-color: var(--b-color-text-primary-2);
  width: 100vw;
  height: 100vh;
}

.ArrowScale {
  transform: scale;
}

.ClaimInfo {
  margin: 40px auto;
  display: flex;
  justify-content: center;
  width: 50%;
  column-gap: 40px;
}

.ClaimInfoItem {
  display: flex;
  align-items: center;
  color: var(--text-primary);
  font-size: 18px;
}

.ClaimInfoItemLabel {
  display: inline-block;
  font-weight: bold;
  margin-right: 10px;
}

.flow-steps-container {
  padding: 0 50px;
}

/* Donut */
.apexcharts-tooltip {
  color: var(--b-color-text-primary-1) !important;
}

/* Style for phone codes dropdown 'react-phone-input-2' */
div.custom-phone-codes {
  width: 60%;
  height: 20px;

  @media screen and (min-width: 600px) {
    height: 28px;
  }

  @media screen and (min-width: 1200px) {
    width: 350px;
    height: 35px;
  }

  input.form-control {
    box-shadow: none;
    font-weight: 500;
    font-family: var(--b-font-family-primary-1);
    font-variant-numeric: lining-nums proportional-nums;
    color: var(--b-color-text-primary-1);
    background-color: var(--b-color-bg-main-2);
    border-radius: 20px;
    border: 1px dashed var(--b-shadow-main);
    padding-left: 100px;
    height: 20px;
    font-size: 16px;
    width: 100%;

    @media screen and (min-width: 600px) {
      height: 28px;
      font-size: 18px;
    }

    @media screen and (min-width: 1200px) {
      height: 35px;
      font-size: 20px;
    }
  }

  div.flag-dropdown,
  div.flag-dropdown:hover,
  div.flag-dropdown.open {
    border: 1px dashed var(--b-shadow-main);
    border-radius: 20px 0px 0px 20px;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--b-font-family-primary-1);
    font-variant-numeric: lining-nums proportional-nums;
    color: var(--b-color-text-primary-1);
    background-color: var(--b-color-bg-main-2);
    width: 80px;

    @media screen and (min-width: 600px) {
      font-size: 18px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 20px;
    }

    div.selected-flag {
      width: 100%;
      padding: 0px;
      border-radius: 20px 0 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ul.country-list {
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      font-family: var(--b-font-family-primary-1);
      font-variant-numeric: lining-nums proportional-nums;
      color: var(--b-color-text-primary-1);
      background-color: var(--b-color-bg-main-2);

      @media screen and (min-width: 600px) {
        font-size: 18px;
      }

      @media screen and (min-width: 1200px) {
        font-size: 20px;
      }
    }
  }
}