.claims-filter-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter_row {
    @media screen and (max-width: 940px) {
        justify-content: center !important;
    }
}

.filter-select {
    @media screen and (max-width: 600px) {
        width: 100%;
    }

    &__control {
        box-shadow: 4px 3px 10px 0px var(--b-shadow-main);
        line-height: 20px;

        @media screen and (min-width: 601px) and (max-width: 800px) {
            max-width: unset !important;
            width: 280px;
        }

        @media screen and (max-width: 600px) {
            height: 31px !important;
            font-size: 16px !important;
            max-width: unset !important;
        }
    }

    &__single-value {
        @media screen and (max-width: 600px) {
            font-size: 16px !important;
        }
    }

    &__menu option {
        background: var(--b-color-main-1);
    }

    &__menu-notice {
        @media screen and (max-width: 600px) {
            padding: 0 !important;
        }
    }

    &__option {
        @media screen and (max-width: 600px) {
            font-size: 16px !important;
            line-height: 20px !important;
            padding: 5px 12px !important;
        }
    }

    &__placeholder {
        @media screen and (max-width: 600px) {
            font-size: 16px !important;
        }
    }
}

.filter-select {
    &__control:hover {
        border: 2px solid var(--b-color-main-1) !important;
    }
}

.filter-select:active {
    &__control:hover {
        border: 2px solid var(--b-color-main-1) !important;
    }
}

.claim-edit-btn {
    width: 30px;
    margin-left: 20px;

    >svg {
        width: 100%;
    }
}

.claims-content {
    display: flex;
    flex-direction: column;
    overflow-x: clip;

    @media screen and (min-width: 1200px) {
        align-items: center;
    }

    @media screen and (max-width: 1000px) {
        display: block;
    }
}

.claims {
    max-width: 1200px;
    width: 100%;
    padding: 40px 10px;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 600px) {
        padding: 40px 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px 2.5rem;
        z-index: 5;

        &__title {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
        }

        &__customer-id {
            display: flex;
            line-height: 16px;
            color: var(--b-color-text-primary-1);
            font-family: var(--b-font-family-primary-1);
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media screen and (max-width: 800px) {
                display: inline-block;
            }
        }

        &__customer-value {
            font-weight: 700;
            margin-left: 10px;

            @media screen and (max-width: 800px) {
                margin-top: 10px;
            }
        }
    }
}

.claim {
    &__content {
        width: 100%;
        text-align: center;
        min-height: 60vh;
    }
}

.claims-filters-grid {
    display: grid;
    grid-template-columns: repeat(4, 170px);
    justify-content: flex-end;
    gap: 10px;
}

.claims-filters-flex {
    display: flex;
    column-gap: 5px;
    margin: 0 22px 30px 0;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 1200px) and (orientation: portrait) {
        justify-content: center;
        margin: 0;
    }

    @media screen and (max-width: 600px) and (orientation: portrait) {
        justify-content: center;
        margin: 0;
        width: 96%;
        flex-wrap: nowrap;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        margin: 0;
        gap: 5px;
    }
}