.QuestionContainer,
.HalfQuestionContainer,
.multi-question-main {

    .question-flow-text {
        font-size: 16px;
        text-align: center;
        margin: 0 auto;
        font-weight: bold;

        @media (min-width: 800px) {
            font-size: 21px;
            margin: 0;
            text-align: left;
        }
    }

    .text-input {

        .MuiFormControl-root {

            >label {
                color: var(--b-color-bg-additional-2);
                font-size: 16px;
                font-weight: 700;

                @media (min-width: 800px) {
                    font-size: 21px;
                }
            }

            >div {
                border-radius: 13px;
                border: 1px solid var(--b-color-bg-additional-5);
                // background: var(--b-color-bg-main-2);
                box-shadow: 0px 0px 8px 0px var(--b-shadow-main);

                >input {
                    color: var(--b-color-text-primary-1);
                    font-family: var(--b-font-family-primary-1);
                    font-size: 16px;
                    font-weight: 600;
                    font-variant-numeric: lining-nums proportional-nums;
                    padding: 18px 15px;

                    @media screen and (min-width: 600px) {
                        font-size: 21px;
                        padding: 15px 35px;
                    }
                }
            }
        }
    }

    .pet-image {
        img {
            width: 114px;
            height: 114px;
            margin: 30px auto 0 auto;

            @media (min-width: 800px) {
                width: 190px;
                height: 190px;
            }

            @media (min-width: 1000px) {
                margin: 0;
                width: 260px;
                height: 260px;
            }
        }
    }

    .flow-item-wrapper {
        display: flex;
        flex-direction: column;

        width: 100%;
        // margin-top: 160px;

        @media (min-width: 1000px) {
            flex-direction: row;
            gap: 50px;

            &>* {
                flex-grow: 1;
            }

            .pet-image {
                flex-grow: 0;
            }
        }

        &_less-margin {
            display: flex;
            flex-direction: column;

            width: 100%;
            margin-top: 30px;

            @media (min-width: 1000px) {
                flex-direction: row;
                gap: 50px;

                &>* {
                    flex-grow: 1;
                }

                .pet-image {
                    flex-grow: 0;
                }
            }
        }
    }

    .flow-item-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }

    .dropdown-select {
        height: 100%;

        .MuiSelect-select {
            border-radius: 13px;
            border: 1px solid var(--b-color-bg-additional-5);
            box-shadow: 0px 0px 8px 0px var(--b-shadow-main);
            background-color: var(--b-color-text-primary-2);
            padding: 18px 15px;
            color: var(--b-color-text-primary-1);
            font-family: var(--b-font-family-primary-1);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border-radius: 13px;
            box-sizing: border-box;
            height: 100%;

            @media screen and (min-width: 600px) {
                font-size: 21px;
                padding: 15px 35px;
            }
        }

        fieldset {
            border: none;
        }
    }

    .flow-date-picker {
        background-color: var(--b-color-text-primary-2);

        div {
            height: 56px;
        }

        fieldset {
            border-radius: 13px;
            border: 1px solid var(--b-color-bg-additional-5);
            box-shadow: 0px 0px 8px 0px var(--b-shadow-main);
        }

        input {
            color: var(--b-color-text-primary-1);
            font-family: var(--b-font-family-primary-1);
            font-size: 16px;
            font-weight: 600;
            font-variant-numeric: lining-nums proportional-nums;

            @media screen and (min-width: 600px) {
                font-size: 20px;
                padding: 15px 35px;
            }
        }
    }

    .flow-multiple-choice {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 15px;

        @media screen and (min-width: 600px) {
            flex-direction: row;

        }

        &-btn {
            min-width: 120px;
            width: auto;
            margin: 0;
        }
    }

    .flow-item-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .flow-title-center {
        align-self: center;
        text-align: center;
    }

    .flow-title-text {
        color: var(--b-color-main-1);
        font-size: 24px;
        font-weight: bold;

        @media (min-width: 800px) {
            font-size: 40px;
        }
    }

    .flow-subtitle-text {
        color: var(--b-color-main-1);
        font-size: 24px;
        font-weight: bold;
        display: none;

        @media (min-width: 800px) {
            display: block;
            font-size: 21px;
        }
    }

    .flow-ocr-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 500px;

        @media (min-width: 800px) {
            max-width: 70%;
        }
    }

    .ocr-upload {
        &-table__title {
            text-align: left;
            color: var(--b-color-text-primary-1);
            font-weight: bold;
            font-size: 16px;

            @media (min-width: 800px) {
                font-size: 21px;
            }
        }

        &-table__wrapper {
            margin-top: 20px;
        }

        &-table__item-name {
            display: flex;
            align-items: center;
            min-height: 30px;
            width: 100%;

            border-radius: 13px;
            border: 1px solid var(--b-color-bg-additional-5);
            background: var(--b-color-bg-main-2);
            box-shadow: 0px 0px 8px 0px var(--b-shadow-main);
            padding: 0 10px;
            margin: 0 3px;

            color: var(--b-color-text-primary-1);
            font-size: 13px;
            font-weight: 700;

            @media (min-width: 800px) {
                font-size: 18px;
            }
        }

        &-table__delete-btn {
            margin-left: auto;
            cursor: pointer;
        }

        &-panda-bg {
            position: absolute;
            top: -56px;
            left: 50%;
            height: 65px;
            width: 120px;
            transform: translateX(-50%);

            @media (min-width: 800px) {
                top: -52px;
                height: 60px;
                width: 105px;
            }
        }

        &-img {
            width: 50px;
            height: 50px;

            @media (min-width: 800px) {
                width: 60px;
                height: 60px;
            }
        }
    }
}

.custom_container {
    .multi-question-main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .flow-content-spacing {
        margin-top: 120px;

        @media (min-width: 600px) {
            margin-top: 160px;
        }
    }

    .multi-question-container {
        .flow-content-spacing {
            margin-top: 0;
        }
    }
}

div.dropdown-select-paper {
    border-radius: 13px;
    max-width: 600px;
}

ul.dropdown-select-list {
    padding: 10px 25px;
    margin: 0;
    background-color: var(--b-color-bg-main-2);
    border-radius: 13px;
    max-height: 50vh;

    @media screen and (min-width: 600px) {
        padding: 15px 35px;
    }

    li {
        padding: 10px;
        color: var(--b-color-text-primary-1);
        font-family: var(--b-font-family-primary-1);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 13px;
        min-height: 32px;
        white-space: normal;

        @media screen and (min-width: 600px) {
            font-size: 21px;
            padding: 10px 20px;
        }
    }

    li:not(:last-child) {
        ::after {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 94%;
            height: 2px;
            background: var(--b-color-bg-additional-2);
        }
    }
}

.wave-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    position: relative;
    z-index: 2;
    padding-bottom: 160px;

    &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: var(--bgi-flow-wave) center/cover no-repeat round;
    }

    @media (min-width: 1000px) {
        padding-bottom: 150px;

        &::before {
            top: 90px;
            background: var(--bgi-flow-wave-mobile);
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}

.full-width-flow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.navigation {
    &__wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 64px 0 36px;
    }

    &__img {
        color: var(--b-color-text-primary);
        cursor: pointer;
    }

    &__title {
        cursor: pointer;
        font-family: var(--font-primary), sans-serif;
        margin-bottom: 0;
        margin-left: 24px;
        text-align: left;
    }
}

.payment-cards {


    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;

        margin-top: 70px;
        padding-bottom: 10px;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        gap: 5px;

        height: 175px;
        max-width: 364px;
        cursor: pointer;

        border-radius: 13px;
        padding: 13px 40px 13px 16px;
        box-shadow: 0px 0px 10px var(--b-shadow-main);

        &_previous {

            label.MuiFormLabel-root {
                padding-left: 17px;
                color: var(--b-color-text-primary-1);
                font-weight: bold;
                -webkit-text-fill-color: var(--b-color-text-primary-1);

                &+div.MuiInputBase-root::before {
                    border: 0;
                }
            }

            label+div.MuiInputBase-root {
                margin-top: 0;

                input {
                    color: var(--b-color-text-primary-1);
                    font-weight: bold;
                    -webkit-text-fill-color: var(--b-color-text-primary-1);
                    pointer-events: none;
                    cursor: pointer;
                }
            }
        }

        &_checked {
            input {
                border-radius: 13px;
                background: white;

                color: var(--b-color-additional-1);
            }

            input:disabled {
                color: var(--b-color-text-primary-1);
                -webkit-text-fill-color: var(--b-color-text-primary-1);
            }
        }
    }


    &__inputs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;

        input {
            height: 22px;
            padding: 10px 13px 12px 13px;
            accent-color: var(--b-color-main-1);
        }

        fieldset {
            border-radius: 13px;
        }
    }

    &__radio {

        label {
            margin-right: 0;
        }

        input+span {
            svg:nth-child(1) {
                color: #00000040;
            }
        }

        input:checked+span {
            svg:nth-child(1) {
                color: var(--b-color-bg-main-2);
            }

            svg:nth-child(2) {
                width: 1.5em;
                height: 1.5em;
                color: var(--b-color-main-1);
                left: -6px;
                top: -6px;
            }
        }
    }
}

.payment-cards__item_previous.payment-cards__item_checked {

    label.MuiFormLabel-root {
        color: white;
        z-index: 1;
        -webkit-text-fill-color: white;
    }

    input.MuiInputBase-input {
        background: var(--b-color-additional-1);
        color: white;
        -webkit-text-fill-color: white;
    }
}

.payment-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
}

.flow-preview-file {
    img {
        width: 100%;
        height: 100%;
        max-height: 67vh;
    }

    &>div {
        position: relative;
    }

    &>div>div:nth-child(1) {
        right: 10px !important;
        top: 10px !important;
        box-shadow: none !important;
        background: transparent !important;

        &>div+div {
            margin-top: 10px;
        }

        &>div {
            border-radius: 50%;
            border-bottom: 0 !important;
            box-shadow: 4px 3px 10px 0px var(--b-shadow-light);
            background-color: var(--b-color-bg-main-1);

            svg path,
            svg polygon {
                stroke: var(--b-color-text-primary-1);
            }
        }
    }
}

.show-final-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50vh;
    gap: 7vh;
    padding: 0 3vw;

    &__subtext {
        color: var(--b-color-text-primary-1);
        font-variant-numeric: lining-nums proportional-nums;
        font-family: var(--b-font-family-primary-1);
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;

        @media screen and (min-width: 600px) {
            font-size: 16px;
        }

        @media screen and (min-width: 900px) {
            font-size: 21px;
        }
    }
}