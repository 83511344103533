.ql-container.ql-snow {
    width: 95%;
    height: 48px;
    border: none;
    background-color: var(--b-color-bg-main-2);
    border-radius: 13px;
    margin-right: 25px;
    background: var(--b-color-bg-main-2);
    box-shadow: 0px 0px 18px 0px var(--b-shadow-main);
}

.quill {
    width: 85%;
}

.ql-toolbar.ql-snow {
    border: none;

    .ql-formats {
        .ql-bold svg {
            display: none;
        }
    }
}

.ql-snow.ql-toolbar button {
    height: 34px !important;
}


.chat-content {
    min-height: 100px;
    flex: 1;
    margin: 40px 0 20px;
    padding: 40px;
    gap: 10px;
    overflow: auto;
    border-radius: 45px;
    background: var(--b-color-bg-main-2);
    box-shadow: 0px 0px 18px 0px var(--b-shadow-main);
}

.chat-title {
    font-weight: 500;
    font-size: 20px;
}

.chat-input {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    outline: none;
    border: none;
    background-color: #f5f6f8;
    padding: 0 20px;
    font-size: 16px;
}

.chat-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    // height: 500px;
    width: 646px;
}

.chat-wrapper-full {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    width: 100%;
}

.chat-message {
    display: flex;
    align-items: flex-end;
    position: relative;
}

.chat-upload {
    position: absolute;
    top: 0;
    left: 21px;
    height: 36px;
    display: flex;

    >svg {
        height: 100%;
    }

    .attach-input {
        width: 511px;
        height: 30px;
        border-radius: 13px;
        border: 1px solid var(--b-color-bg-additional-5);
        background: var(--b-color-bg-main-2);
        box-shadow: 0px 0px 18px 0px var(--b-shadow-main);
        color: var(--b-color-bg-additional-2);
        font-family: var(--b-font-family-primary-1);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-left: 15px;
    }

    .attach-input::placeholder {
        color: var(--b-color-bg-additional-2);
        font-family: var(--b-font-family-primary-1);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-left: 15px;
    }
}

.chat-item {
    background-color: var(--b-color-bg-additional-4);
    border-radius: 50px 0 50px 50px;
    padding: 20px;
    margin-left: auto;
    min-width: 300px;
    font-size: 15px;
    display: grid;
    width: 90%;
    margin-bottom: 20px;

    &.left {
        background-color: #fff2e5;
        margin-left: 0;
        margin-right: auto;
        border-radius: 0 50px 50px 50px;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        gap: 10px;
    }

    &__user {
        color: var(--b-color-main-1);
        font-variant-numeric: lining-nums proportional-nums;
        font-family: var(--b-font-family-primary-1);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &__date {
        color: grey;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: var(--b-font-family-primary-1);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__message {
        text-align: left;
        color: var(--b-color-text-primary-1);
        font-variant-numeric: lining-nums proportional-nums;
        font-family: var(--b-font-family-primary-1);
        font-size: 18px;
        font-style: normal;
        font-weight: 420;
        line-height: normal;
    }

    &__image {
        width: 100px;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }
}

.chat-item.left {
    .chat-item__body {
        align-items: flex-start;
    }
}

.chat-btn {
    position: relative;

    &__notif {
        position: absolute;
        z-index: 1;
        left: 21px;
        top: -6px;
        padding: 3px;
        background-color: var(--b-color-main-1);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        color: var(--b-color-text-primary-2);
        font-size: 12px;
        line-height: 100%;
    }
}

.chat-question {
    display: grid;
    gap: 10px;

    &__title {
        color: var(--b-color-text-primary-1);
        font-variant-numeric: lining-nums proportional-nums;
        font-family: var(--b-font-family-primary-1);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        gap: 3px;
        align-items: center;
    }

    &__variations {
        display: flex;
        gap: 10px;
    }

    &__variation {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        color: var(--b-color-text-primary-2);
        background: var(--b-color-main-2);
        border-radius: 14px;
        margin: 0px;
        transition: all 0.3s;
        cursor: pointer;
    }

    &__open-q {
        display: flex;
        align-items: flex-end;
        gap: 10px;
    }

    &__upload {
        position: relative;
    }
}

.chat-loader {
    background: rgba($color: var(--b-color-text-primary-2), $alpha: 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}